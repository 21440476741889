import React, { useEffect, useState } from "react";
import Token from "../../assets/token.png";
import USDT from "../../assets/usdt.png";
import ETH from "../../assets/eth.png";
import useGetEthPrice from "../../hooks/useEthPrice";
import { ClipLoader } from "react-spinners";

import {
  AmountInoutWrapper,
  AmountWrapper,
  BuyText,
  Digit,
  Divider,
  HeadingWrapper,
  InputField,
  LgDigit,
  LiveButton,
  MdDigit,
  SoldPriceWrapper,
  SwapWrapper,
  SwapWrapperMain,
  TokenWrapper,
} from "../Sections/Hero/hero.style";
import ProgressBar from "../Shared/LinearProgress";
import SwapTab from "../Shared/Tab";
import { BuyButton } from "../Shared/Button/Button.style";
import { useWeb3React } from "@web3-react/core";
// import { AbstractConnector } from "@web3-react/abstract-connector";
import { useDispatch } from "react-redux";
import { setOpenModal } from "../../store/slices/authSlice";
import { useBalance } from "../../store/user/hook";
import { usePresale } from "../../store/presale/hook";
import { useBuy, BuyTransactionStatus } from "../../hooks/useBuy";
import {
  ApprovalTransactionStatus,
  useAllowance,
  useApproval,
} from "../../hooks/useApprove";
import { presaleContract, usdtAddress } from "../../config";
import { unitParser } from "../../utilities";
import { round } from "lodash";
import { ClaimTransactionStatus, useClaim } from "hooks/useClaim";
import { toastErrorHandler } from "utilities/toast";
import PresaleCounteDown from "components/Shared/Counter";
import Countdown from "react-countdown";
// import { TextField } from "@mui/material";

export enum BUY_CURRENCY {
  USDT,
  ETH,
}

const Swapper = () => {
  const { active, account } = useWeb3React();
  // const [, setActiveConnector] = useState<AbstractConnector | null>(null);
  const dispatch = useDispatch();
  const [selectedCurrency, setSelectedCurrency] = React.useState<BUY_CURRENCY>(
    BUY_CURRENCY.ETH
  );

  const sellData = usePresale();

  const [inputAmount, setInputAmount] = useState<number>(
    selectedCurrency === BUY_CURRENCY.ETH ? 0.05 : 100
  );
  const [blockStarAmount, setBlockStarAmount] = useState<number>();

  const [amountError, setAmountError] = React.useState<boolean>();
  const [errorMessage, setErrorMessage] = React.useState<string>();

  const ethPrice = useGetEthPrice();
  const blockStarPrice = 0.03;

  useEffect(() => {
    if (selectedCurrency === BUY_CURRENCY.ETH) {
      setBlockStarAmount((inputAmount * Number(ethPrice)) / blockStarPrice);
    } else {
      setBlockStarAmount(inputAmount / blockStarPrice);
    }
  }, [blockStarPrice, ethPrice, selectedCurrency]);

  const userBalance = useBalance();

  const allowance = useAllowance(presaleContract, usdtAddress);

  const { isApprovalRequired, triggeredApproval, approvalTxStatus } =
    useApproval(
      allowance,
      unitParser(inputAmount, 6),
      presaleContract,
      usdtAddress
    );

  const { buyTxStatus, triggeredBuy } = useBuy();

  const { claimTxStatus, triggeredClaim } = useClaim();

  const approveOrBuy = async () => {
    if (amountError) return;
    if (selectedCurrency === BUY_CURRENCY.USDT) {
      if (inputAmount > userBalance?.usdtBalance) {
        toastErrorHandler("Insufficient USDT Balance", "");
        return;
      }
      try {
        if (isApprovalRequired) {
          await triggeredApproval();
        }

        await triggeredBuy(true, unitParser(inputAmount, 6));
      } catch (err) {
        console.log(err);
      }
    } else {
      if (inputAmount > userBalance?.ethBalance) {
        toastErrorHandler("Insufficient ETH Balance", "");
        return;
      }

      try {
        await triggeredBuy(false, unitParser(inputAmount));
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    if (blockStarAmount + userBalance?.token_balance >= sellData?.maximumBuy) {
      setAmountError(true);
      setErrorMessage("Invalid Amount: user max buy exceed");
      return;
    }

    if (selectedCurrency === BUY_CURRENCY.ETH) {
      if (inputAmount >= sellData?.minimumBuyInEth) {
        setAmountError(false);
      } else {
        setAmountError(true);
        setErrorMessage(
          `Invalid Amount: minimum eth amount ${sellData?.minimumBuyInEth}`
        );
        return;
      }
    } else {
      if (inputAmount >= sellData?.minimumBuyInUsdt) {
        setAmountError(false);
      } else {
        setAmountError(true);
        setErrorMessage(
          `Invalid Amount: minimum usdt amount ${sellData?.minimumBuyInUsdt}`
        );
        return;
      }
    }
  }, [
    blockStarAmount,
    inputAmount,
    selectedCurrency,
    sellData?.minimumBuyInEth,
    sellData?.minimumBuyInUsdt,
    sellData?.maximumBuy,
    userBalance?.token_balance,
  ]);

  const targetDate = new Date("2024-02-07T23:59:59");
  const renderer = ({ days, hours, minutes, completed }: any) => {
    if (completed) {
      console.log("complted");
      // <h1>PreSlae Completed</h1>
    } else {
      return <PresaleCounteDown days={days} hours={hours} minutes={minutes} />;
    }
  };

  const blockAmountHandler = (newAmount: number) => {
    setBlockStarAmount(round(newAmount, 4));

    if (selectedCurrency === BUY_CURRENCY.ETH) {
      // return (inputAmount * Number(ethPrice)) / blockStarPrice;
      setInputAmount(round((newAmount * blockStarPrice) / Number(ethPrice), 4));
    } else {
      setInputAmount(round(newAmount * blockStarPrice, 4));
    }
  };

  const inputAmountHandler = (newAmount: string) => {
    console.log("newAmount", newAmount);

    newAmount.replace(/,/g, ".");

    // Regular expression to allow numbers and a single dot anywhere
    const regex = /^[0-9]*\.?[0-9]*$/;

    // Check if the input is empty or matches the regex
    if (newAmount === "" || regex.test(newAmount)) {
      console.log("nextUserInput", newAmount);

      // Use parseFloat to handle float numbers and round to 4 decimal places
      setInputAmount(round(parseFloat(newAmount), 4));

      if (selectedCurrency === BUY_CURRENCY.ETH) {
        setBlockStarAmount(
          round((parseFloat(newAmount) * Number(ethPrice)) / blockStarPrice, 4)
        );
      } else {
        setBlockStarAmount(round(parseFloat(newAmount) / blockStarPrice, 4));
      }
    }

    // setInputAmount(round(newAmount, 4));

    // if (selectedCurrency === BUY_CURRENCY.ETH) {
    //   setBlockStarAmount(
    //     round((newAmount * Number(ethPrice)) / blockStarPrice, 4)
    //   );
    // } else {
    //   setBlockStarAmount(round(newAmount / blockStarPrice, 4));
    // }
  };

  const currencyHandler = (newSelectedCurrency: BUY_CURRENCY) => {
    setSelectedCurrency(round(newSelectedCurrency, 4));

    if (selectedCurrency === BUY_CURRENCY.ETH) {
      setBlockStarAmount(
        round((inputAmount * Number(ethPrice)) / blockStarPrice, 4)
      );
    } else {
      setBlockStarAmount(round(inputAmount / blockStarPrice, 4));
    }
  };

  return (
    <>
      <SwapWrapperMain>
        <Countdown date={targetDate} renderer={renderer} />
        <SwapWrapper>
          <HeadingWrapper className="w-full flex justify-between item-center">
            <BuyText>Buy $BLKS Token</BuyText>
            {sellData?.presaleStatus && <LiveButton>Live</LiveButton>}
          </HeadingWrapper>
          <div className="flex flex-col g-8">
            <ProgressBar
              parcentage={(sellData.totalRaised / sellData.maxRaise) * 100}
            />
            <div className="flex justify-end w-full">
              <Digit>
                ${sellData.totalRaised ? sellData.totalRaised : 0} / $
                {sellData.maxRaise}
              </Digit>
            </div>
          </div>
          <div className="flex justify-between item-center">
            <Divider />

            <Digit> 1BLKS=$0.03 • LISTING PRICE = $0.06 </Digit>

            <Divider />
          </div>

          <div className="flex justify-between item-center">
            <SoldPriceWrapper className="flex justify-between">
              {userBalance?.token_balance > 0 ? (
                <div className="flex flex-col g-8">
                  <Digit>My Reserved Tokens</Digit>
                  <div className="flex g-4">
                    <TokenWrapper>
                      <img src={Token} className="img-contain" alt="BLKS" />
                    </TokenWrapper>
                    <div className="flex flex-col g-4 item-start">
                      <LgDigit>{round(userBalance?.token_balance, 2)}</LgDigit>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col g-8">
                  <Digit>TOKEN SOLD</Digit>
                  <div className="flex g-4">
                    <TokenWrapper>
                      <img src={Token} className="img-contain" alt="BLKS" />
                    </TokenWrapper>
                    <div className="flex flex-col g-4 item-start">
                      {userBalance?.token_balance > 0 ? (
                        <>
                          {" "}
                          <LgDigit>
                            {round(userBalance?.token_balance, 2)}
                          </LgDigit>
                        </>
                      ) : (
                        <>
                          <LgDigit>
                            {sellData?.tokenSold
                              ? round(sellData?.tokenSold, 2)
                              : 0}
                          </LgDigit>
                          <Digit> / {sellData?.maxTokenSell}</Digit>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div className="flex flex-col g-8">
                {userBalance?.eth_usdt_balance + userBalance?.usdt_paid > 0 ? (
                  <>
                    <Digit>My Investment ($)</Digit>
                    <div className="flex g-4">
                      {/* <TokenWrapper>
                  <img src={USDT} className="img-contain" />
                </TokenWrapper> */}
                      <div className="flex flex-col g-4 item-start">
                        <LgDigit>
                          {round(
                            userBalance?.eth_usdt_balance +
                              userBalance?.usdt_paid,
                            2
                          )}
                        </LgDigit>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <Digit>USD RAISED</Digit>
                    <div className="flex g-4">
                      {/* <TokenWrapper>
                  <img src={Token} className="img-contain" />
                </TokenWrapper> */}
                      <div className="flex flex-col g-4 item-start">
                        <LgDigit>
                          {sellData?.totalRaised ? sellData?.totalRaised : 0}
                        </LgDigit>
                        <Digit> / {sellData?.maxRaise}</Digit>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </SoldPriceWrapper>
          </div>

          <div className="flex justify-between item-center g-16">
            <Divider />
            <>
              {account && active ? (
                <MdDigit>
                  BALANCE:{" "}
                  {selectedCurrency === BUY_CURRENCY.ETH
                    ? userBalance?.ethBalance
                    : userBalance?.usdtBalance}
                </MdDigit>
              ) : (
                <MdDigit>CONNECT WALLET</MdDigit>
              )}
            </>
            <Divider />
          </div>

          {sellData?.isCLaimEnable && !sellData?.presaleStatus ? (
            <div className="flex justify-between item-center">
              <SoldPriceWrapper className="flex justify-between">
                <div className="flex flex-col g-8">
                  <Digit>Claimable on Presale</Digit>
                  <div className="flex g-4">
                    <TokenWrapper>
                      <img src={Token} className="img-contain" alt="BLKS" />
                    </TokenWrapper>
                    <div className="flex flex-col g-4 item-start">
                      <LgDigit>{userBalance?.claimable_amount}</LgDigit>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col g-8">
                  <Digit>Claimable on Vesting</Digit>
                  <div className="flex g-4">
                    <TokenWrapper>
                      <img src={Token} className="img-contain" alt="BLKS" />
                    </TokenWrapper>
                    <div className="flex flex-col g-4 item-start">
                      <LgDigit>{userBalance?.vesting_amount}</LgDigit>
                    </div>
                  </div>
                </div>
              </SoldPriceWrapper>
            </div>
          ) : (
            <>
              <SwapTab
                setSelectedCurrency={currencyHandler}
                selectedCurrency={selectedCurrency}
              />
              <AmountInoutWrapper className="flex justify-between w-full g-16">
                <AmountWrapper>
                  <TokenWrapper>
                    <img
                      src={selectedCurrency === BUY_CURRENCY.ETH ? ETH : USDT}
                      className="img-contain"
                      alt={
                        selectedCurrency === BUY_CURRENCY.ETH ? "ETH" : "USDT"
                      }
                    />
                  </TokenWrapper>

                  <InputField
                    type="number"
                    placeholder="Enter Amount"
                    value={inputAmount}
                    onChange={(e) => inputAmountHandler(e.target.value)}
                    inputMode="decimal"
                    autoComplete="off"
                    autoCorrect="off"
                  />
                </AmountWrapper>
                <AmountWrapper>
                  <TokenWrapper>
                    <img src={Token} className="img-contain" alt="BLKS" />
                  </TokenWrapper>
                  <InputField
                    placeholder="Enter Amount"
                    type="number"
                    value={blockStarAmount}
                    onChange={(e) =>
                      blockAmountHandler(parseFloat(e.target.value))
                    }
                    inputMode="text"
                  />
                </AmountWrapper>
              </AmountInoutWrapper>
            </>
          )}
          <>
            {amountError && <p style={{ color: "red" }}>{errorMessage}</p>}
            {account && active ? (
              sellData?.isCLaimEnable && !sellData?.presaleStatus ? (
                <BuyButton
                  onClick={() => triggeredClaim()}
                  disabled={
                    claimTxStatus === ClaimTransactionStatus.CLAIM_LOADING
                  }
                >
                  {" "}
                  {claimTxStatus === ClaimTransactionStatus.CLAIM_LOADING ? (
                    <>
                      <ClipLoader color="black" size={20} /> &nbsp;{" "}
                      {`Claim ${userBalance?.user_claimable_token}`}
                    </>
                  ) : (
                    `Claim ${userBalance?.user_claimable_token}`
                  )}
                </BuyButton>
              ) : (
                <BuyButton
                  onClick={() => approveOrBuy()}
                  disabled={
                    buyTxStatus === BuyTransactionStatus.BUY_LOADING ||
                    !inputAmount ||
                    approvalTxStatus ===
                      ApprovalTransactionStatus.APPROVAL_LOADING
                  }
                >
                  {" "}
                  {buyTxStatus === BuyTransactionStatus.BUY_LOADING ||
                  approvalTxStatus ===
                    ApprovalTransactionStatus.APPROVAL_LOADING ? (
                    <>
                      <ClipLoader color="black" size={20} /> &nbsp; Buy
                    </>
                  ) : (
                    "Buy"
                  )}
                </BuyButton>
              )
            ) : (
              <BuyButton
                onClick={() => {
                  dispatch(setOpenModal(true));
                }}
              >
                Connect Wallet
              </BuyButton>
            )}
          </>
        </SwapWrapper>
      </SwapWrapperMain>
    </>
  );
};

export default Swapper;
