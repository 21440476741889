import { ethRpcUrl } from "config";

export const chainConfig = {
  1: [
    {
      chainId: "0x1",
      chainName: "Ethereum",
      nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18,
      },
      rpcUrls: [ethRpcUrl],
      blockExplorerUrls: ["https://etherscan.io/"],
      // iconUrls: ['https://unifarm.co/app/assets/icons/bnb.png'],
    },
  ],
  5: [
    {
      chainId: "0x5",
      chainName: "Goerli",
      nativeCurrency: {
        name: "gETH",
        symbol: "ETH",
        decimals: 18,
      },
      rpcUrls: ["https://rpc.ankr.com/eth_goerli"],
      blockExplorerUrls: ["https://goerli.etherscan.io/"],
      // iconUrls: ['https://unifarm.co/app/assets/icons/bnb.png'],
    },
  ],
};
