import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
// import { Typography } from "@mui/material";
import { round } from "lodash";
import { ProgressText } from "./style";

const BorderLinearProgress = styled(LinearProgress)(() => ({
  width: "100%",
  height: 33,
  borderRadius: 20,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "rgba(217, 217, 217, 0.20)",
    //   theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    background: "linear-gradient(83deg, #FFF 12.28%, #7AEBF1 103.24%)",
    // theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

// const WhiteTextTypography = withStyles({
//   root: {
//     color: "#FFFFFF"
//   }
// })(Typography);
interface ProgressBarProps {
  parcentage: number;
}
const ProgressBar = ({ parcentage }: ProgressBarProps) => {
  return (
    <>
      {/* <div>
        <div className="progress"> 40%</div>
      </div> */}

      <Box position="relative" display="inline-flex" style={{ width: "100%" }}>
        <BorderLinearProgress variant="determinate" value={parcentage} />
        {/* <BorderLinearProgress
        color={hex ? undefined : color}
        variant="determinate"
        // value={value}
      /> */}
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <ProgressText isAboveSixtyPercent={parcentage > 60}>
            {" "}
            {round(parcentage, 5)}%
          </ProgressText>

          {/* <WhiteTextTypography variant="body2">{`${value}%`}</WhiteTextTypography> */}
        </Box>
      </Box>
    </>
  );
};

export default ProgressBar;
