import { Interface } from "ethers";

/**
 * get token call data
 * @param iface
 * @param methodName
 * @param values
 * @returns
 */
export const getTokenCallData = (
  iface: Interface,
  methodName: string,
  values?: any[]
): string => {
  return iface.encodeFunctionData(methodName, values);
};

/**
 * get presale calldata
 * @param iface
 * @param methodName
 * @param values
 * @returns
 */
export const getPresaleCallData = (
  iface: Interface,
  methodName: string,
  values?: any[]
): string => {
  return iface.encodeFunctionData(methodName, values);
};
