import Tab from "@mui/material/Tab";
import styled from "styled-components";
import Tabs from "@mui/material/Tabs";
import { MediaBreakpoints } from "../../../mediaQuery";

type TTab = {
  isActive?: boolean;
};

export const TabWrapper = styled(Tabs)`
  & .MuiTabs-flexContainer {
    @media (${MediaBreakpoints.mobile}) {
      flex-direction: column;
    }
  }
  & .MuiTabs-indicator {
    display: none;
  }
  border-radius: 10px !important;
`;

export const MidTabWrapper = styled(TabWrapper)`
  & .MuiTabs-flexContainer {
    justify-content: space-around !important;

    @media (${MediaBreakpoints.mobile}) {
      align-items: center !important;
    }

    ${MediaBreakpoints.ipad} {
      align-items: center !important;
    }
  }
`;

export const StyledTab = styled(Tab)<TTab>`
  border-radius: 8.096px !important;
  border: 1px solid rgba(217, 217, 217, 0.1) !important;
  background: rgba(217, 217, 217, 0.1) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48% !important;
  font-size: 13px !important;
  height: 42px !important;
  font-weight: 700 !important;
  color: #fff !important;
  font-family: Inter !important;
  padding: 0 !important;
  min-height: auto !important;
  box-shadow: unset !important;
  &.active {
    border: 1px solid rgb(115, 251, 253) !important;
    color: #000 !important;
    background: linear-gradient(
      89deg,
      rgb(115, 251, 253) 85%,
      #ffff
    ) !important;
  }
  @media (${MediaBreakpoints.mobile}) {
    width: 100% !important;
    margin-top: 10px !important;
  }
`;
