import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMulticallUserBalance } from "../../utilities/getMulticallPresaleData";
import { useWeb3React } from "@web3-react/core";
import { setBalance } from ".";
import { AppState } from "..";

export const useSetUser = () => {
  const dispatch = useDispatch();
  const { chainId, account } = useWeb3React();

  useEffect(() => {
    if (!chainId || !account) return;
    const getMulticallData = async () => {
      try {
        const balance: { [key in string]: number } =
          await getMulticallUserBalance(chainId, String(account));

        dispatch(
          setBalance({
            usdtBalance: balance?.["usdtBalance"],
            ethBalance: balance?.["ethBalance"],
            eth_usdt_balance: balance?.["eth_usdt_balance"],
            usdt_paid: balance?.["usdt_paid"],
            token_balance: balance?.["token_balance"],
            claimable_amount: balance?.["claimable_amount"],
            vesting_amount: balance?.["vesting_amount"],
            claimed_token: balance?.["claimed_token"],
            user_claimable_token: balance?.["user_claimable_token"],
          })
        );
      } catch (err) {
        if (err instanceof Error) {
          console.log("err", err?.message);
        }
      }
    };

    getMulticallData();
  }, [account, chainId, dispatch]);
};

export const useBalance = () => {
  return useSelector((state: AppState) => state.user);
};
