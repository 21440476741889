import styled from "styled-components";
import { MediaBreakpoints } from "../../mediaQuery";

export const NavbarWrapper = styled.div`
  

   background-color:#000;
   box-shadow: 0px 4px 30px 0px rgba(109, 241, 252, 0.10);
  height: 110px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  @media (${MediaBreakpoints.mobile}) {
      height: 75px;
      // width: 122px;
    }
  
    ${MediaBreakpoints.ipad} {
      height: 100%;    }
}
`;

export const LogoWrapper = styled.div`
  overflow: hidden;

  @media (${MediaBreakpoints.mobile}) {
    width: 150px;
  }

  ${MediaBreakpoints.ipad} {
    width: 122px;
  }
`;

export const WalletWrapper = styled.div`
  width: 360px;
  //   height: 62px;
  //   flex-shrink: 0;

  cursor: pointer;

  padding: 16px;

  border-radius: 20px;
  border: 1px solid #7aebf1;

  background: #fff;
`;

export const WalletText = styled.p`
  color: #242323;

  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const MetamaskLogoWrapper = styled.div`
  width: 37px;
  height: 37px;
  overflow: hidden;
  border-radius: 50%;
`;
