import React, { useState } from "react";
import {
  LogoWrapper,
  MetamaskLogoWrapper,
  NavbarWrapper,
  WalletText,
  WalletWrapper,
} from "./navbar.style";
import PreSaleContainer from "../Shared/Container";
import Logo from "../../assets/BLog.png";
import PresaleButton from "../Shared/Button";
import CustomizedDialogs from "../Shared/Modal";
import Metamask from "../../assets/metamask.png";
import { ETH_CHAIN, injected } from "../../connectors";
import { useWeb3React } from "@web3-react/core";
import { AbstractConnector } from "@web3-react/abstract-connector";
import { miniWalletAddress } from "../../utilities";
import { setOpenModal } from "../../store/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import { useChangeAppChainId } from "hooks/useSwitchNetwork";
import { NavHashLink } from "react-router-hash-link";

const Navbar = () => {
  const { activate, account, active } = useWeb3React();
  const [, setActiveConnector] = useState<AbstractConnector | null>(null);
  const dispatch = useDispatch();
  const openModal = useSelector((state: AppState) => state.auth.openModal);

  // const [openModal, setOpenModal] = useState<boolean>(false);

  const handleOpenModal = () => {
    dispatch(setOpenModal(true));
  };
  const handleCloseModal = () => {
    dispatch(setOpenModal(false));
  };

  const switchAppchainId = useChangeAppChainId();
  const activation = async (connector: AbstractConnector | undefined) => {
    try {
      connector &&
        activate(connector, undefined, true)
          .then(() => {
            console.log("Connected to metamask");
            setActiveConnector(connector);
            // setIsWalletConnected(true);
            dispatch(setOpenModal(false));
          })
          .catch((error: any) => {
            console.log(error, "error in metamask");
            switchAppchainId(ETH_CHAIN);
          });
    } catch (error) {
      console.log("Error in  activation: ", error);
    }
  };

  return (
    <>
      <NavbarWrapper>
        <PreSaleContainer>
          <div className="flex justify-between item-center w-full">
            <LogoWrapper className="logo-contain">
              <img src={Logo} />
            </LogoWrapper>{" "}
            <div id="menu-time" className="menu-data animate__animated ">
              <NavHashLink smooth to="/#about-us">
                About Us
              </NavHashLink>
              <NavHashLink smooth to="/#why-choose-us">
                Why Choose Us
              </NavHashLink>
              <NavHashLink smooth to="/#benefits">
                Benefits
              </NavHashLink>
              <NavHashLink smooth to="/#whitepaper">
                Whitepaper
              </NavHashLink>
            </div>
            <div>
              <>
                {account && active ? (
                  <WalletWrapper className="flex justify-between item-center w-full">
                    <WalletText>{miniWalletAddress(account)}</WalletText>
                    <MetamaskLogoWrapper>
                      <img src={Metamask} className="img-contain" />
                    </MetamaskLogoWrapper>
                  </WalletWrapper>
                ) : (
                  <PresaleButton
                    label="Connect Wallet"
                    handleClick={handleOpenModal}
                  />
                )}
              </>
            </div>
          </div>
        </PreSaleContainer>
      </NavbarWrapper>

      <CustomizedDialogs
        open={openModal}
        onClose={handleCloseModal}
        title="Connect Wallet"
      >
        {/* <div>Wallet</div> */}

        <WalletWrapper
          onClick={() => {
            activation(injected);
          }}
          className="flex justify-between item-center w-full"
        >
          <WalletText>MetaMask</WalletText>

          <MetamaskLogoWrapper>
            <img src={Metamask} className="img-contain" />
          </MetamaskLogoWrapper>
        </WalletWrapper>
      </CustomizedDialogs>
    </>
  );
};

export default Navbar;
