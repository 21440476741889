import React from "react";
import {
  CountDownHeading,
  CountText,
  Counter,
  SpanText,
  Wrapper,
} from "./counter.style";

const PresaleCounteDown = ({ days, minutes, hours }: any) => {
  return (
    <div className="flex flex-col g-16">
      <CountDownHeading>Presale Ends in </CountDownHeading>
      <Wrapper>
        <Counter>
          <SpanText>{days}</SpanText>
          <CountText>Days</CountText>
        </Counter>
        <Counter>
          <SpanText>{hours}</SpanText>
          <CountText>Hours</CountText>
        </Counter>
        <Counter>
          <SpanText>{minutes}</SpanText>
          <CountText>Minutes</CountText>
        </Counter>
      </Wrapper>
    </div>
  );
};

export default PresaleCounteDown;
