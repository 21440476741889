// import Button from "@mui/material/Button";
import { StyledButton } from "./Button.style";

type TButtonProps = {
  label: string;
  handleClick: () => void;
};

const PresaleButton = ({ label, handleClick }: TButtonProps) => {
  return (
    <>
      <StyledButton variant="contained" onClick={handleClick}>
        {label}
      </StyledButton>
    </>
  );
};

// export default function ContainedButtons() {
//     return (
//       <Stack direction="row" spacing={2}>
//         <Button variant="contained">Contained</Button>
//         <Button variant="contained" disabled>
//           Disabled
//         </Button>
//         <Button variant="contained" href="#contained-buttons">
//           Link
//         </Button>
//       </Stack>
//     );
//   }

export default PresaleButton;
