import styled from "styled-components";

type TPercentage = {
  isAboveSixtyPercent?: boolean;
};

export const ProgressText = styled.p<TPercentage>`
  color: ${(props) => (props.isAboveSixtyPercent ? "#000" : "#fff")};

  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
