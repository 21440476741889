import styled from "styled-components";
import Button from "@mui/material/Button";
import { MediaBreakpoints } from "../../../mediaQuery";

export const StyledButton = styled(Button)`
  width: 200px !important;
  border-radius: 4px !important;
  height: 50px !important;
  color: #000 !important;
  text-transform: capitalize !important;
  font-family: Inter !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  // background: linear-gradient(83deg, #1c274c 12.28%, #7aebf1 103.24%);
  background: #73fbfd !important;
  transition: 0.3s;

  &:hover {
    box-shadow: 0px 4px 50px 0px rgba(115, 251, 253, 0.41) !important;
  }

  @media (${MediaBreakpoints.mobile}) {
    width: 145px !important;
    font-size: 14px !important;
    padding: 0px !important;
    height: 40px !important;
  }

  ${MediaBreakpoints.ipad} {
    width: 160px !important;
    font-size: 16px !important;
  }
`;

export const BuyButton = styled(StyledButton)`
  // background: linear-gradient(269deg, #fff -10.47%, #7aebf1 86.76%) !important;
  background: #74fcfc !important;
  width: 100% !important;
  color: #000 !important;
`;
