import styled from "styled-components";
import { MediaBreakpoints } from "../../../mediaQuery";

export const AddressWrapper = styled.div`
  width: 80%;
  padding: 40px 0;
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: center;
  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
    flex-direction: column;
    padding: 24px 0;
    gap: 16px;
  }

  ${MediaBreakpoints.ipad} {
    width: 100%;
    flex-direction: column;
  }
`;

export const MainWrapper = styled.div`
  width: 100%;
  //   padding-bottom: 80px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  // background: linear-gradient(269deg, #fff -10.47%, #7aebf1 86.76%);
  background: #2d323a;
`;

export const VerticalDivider = styled.div`
  height: 90px;
  width: 3px;
  border-radius: 10px;
  // background: #1c274c;
  background: #fff;
`;

export const AddressText = styled.p`
  color: #fff;

  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @media (${MediaBreakpoints.mobile}) {
    font-size: 20px;
  }
`;

export const MiniAddressText = styled.p`
  color: #fff;

  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media (${MediaBreakpoints.mobile}) {
    font-size: 10px;
    margin-top: 8px;
  }
`;

export const ContractBtnWrapper = styled.div`
  @media (${MediaBreakpoints.mobile}) {
    justify-content: center;
    align-items: center;
  }
`;

export const TokenWrapper = styled.div`
  width: 83px;
  height: 83px;
  overflow: hidden;

  @media (${MediaBreakpoints.mobile}) {
    width: 48px;
    height: 48px;
  }

  ${MediaBreakpoints.ipad} {
    width: 48px;
    height: 48px;
  }
`;
