import axios from "axios";

const getEthPrice = async (): Promise<number> => {
  const response = (await axios
    .get(
      `https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=USD`
    )
    .then((data) => data?.data)) as { ethereum: { usd: number } };

  console.log("response", response);

  const result = response?.ethereum?.usd;

  return result;
};

export default getEthPrice;
