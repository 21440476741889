// export const mobile = "@media(max-width:767px)";
// export const mediummobile = "@media(max-width:414px)";
// export const smallmobile = "@media(max-width:375px)";
// export const ipad = "@media(min-width:768px) and (max-width:1024px)";

// export const minidesk = "@media(min-width:1024px) and (max-width:1330px)";
// export const minidesk2 = "@media(min-width:1330px) and (max-width:1400px)";
// export const minidesk3 = "@media(min-width:1400px) and (max-width:1535px)";
// export const minidesk4 = "@media(min-width:1680px)";

export const MediaBreakpoints = {
  mobile: "max-width:767px",
  mobile2: "(max-width: 767px)",
  ipad: "@media(min-width:768px) and (max-width:900px)",
  minidesk: "@media(min-width:901px) and (max-width:1330px)",
  minidesk2: "@media(min-width:1330px) and (max-width:1399px)",
  // tablet: "@media(min-width: 768px) and (max-width: 900px)",
};
