import { useCallback, useState } from "react";
import { gasPrice } from "../utilities";
import { useWeb3React } from "@web3-react/core";
import { toastErrorHandler, toastSuccessHandler } from "../utilities/toast";
import { getPresaleInterface } from "../utilities/contract/Interface";
import { getPresaleCallData } from "../utilities/contract/encode";
import { presaleContract } from "../config";

// approval transactions
export enum ClaimTransactionStatus {
  /** on loading */
  CLAIM_LOADING,
  /** on confirmed */
  CLAIM_CONFIRMED,
  /** on error */
  CLAIM_ERROR,
}

export interface ClaimResponse {
  /** transaction status */
  claimTxStatus: ClaimTransactionStatus;
  /** approval callback function */
  triggeredClaim: () => Promise<void>;
  /** tx */
  tx?: boolean;
}

export const useClaim = (): ClaimResponse => {
  // to check if approval required

  const [tx, setTx] = useState<boolean>(false);

  let [claimTxStatus, setClaimTxStatus] = useState<ClaimTransactionStatus>();
  const { account, library } = useWeb3React();

  const iface = getPresaleInterface();

  const triggeredClaim = useCallback(async () => {
    try {
      // set approval status loading

      setClaimTxStatus(ClaimTransactionStatus.CLAIM_LOADING);

      const callData = getPresaleCallData(iface, "claimTokens", []);
      // estimate gas on
      let gasLimit = await library.getSigner().estimateGas({
        from: account,
        to: presaleContract,
        data: callData,
        value: 0,
      });

      // gas price
      let gas_price = await gasPrice(library);

      const approve = await library.getSigner().sendTransaction({
        from: account,
        to: presaleContract,
        data: callData,
        gasLimit,
        gasPrice: gas_price.mul(2),
        value: 0,
      });

      // waiting atleast two confirmation
      await approve.wait(2);
      setTx(approve?.transactionHash);
      // set approval transaction status to confirm
      setClaimTxStatus(ClaimTransactionStatus.CLAIM_CONFIRMED);

      toastSuccessHandler("Claimed successful");
      window.location.reload();
    } catch (err) {
      console.log("useClaim Failed", err);
      // set error
      setClaimTxStatus(ClaimTransactionStatus.CLAIM_ERROR);
      toastErrorHandler("Claim Error", err?.message?.slice(0, 20));
    }
  }, [library, account, iface]);

  return {
    /** @ts-ignore */
    claimTxStatus,
    /** @ts-ignore */
    triggeredClaim,
    tx,
  };
};
