import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
// import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {
  useIpad,
  useMobile,
  useMobile2,
  useMobile3,
} from "mediaQuery/useDevice";

const PreSaleContainer = ({ children }: any) => {
  const mobile = useMobile();
  const mobile2 = useMobile2();
  const mobile3 = useMobile3();
  const ipad = useIpad();

  const isitWeb = mobile || mobile2 || mobile3 || ipad;
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="xl" style={{ padding: !isitWeb ? "0 50px" : "" }}>
        {children}
      </Container>
    </React.Fragment>
  );
};

export default PreSaleContainer;
