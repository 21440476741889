import React, { useEffect, useRef, useState } from "react";
import {
  BtnWrapper,
  DisSendButton,
  Heading,
  InputWrapper,
  InputWrapperName,
  InsideWrapper,
  InviteInputField,
  InviteWrapper,
  SendButton,
} from "./invite.style";
import PreSaleContainer from "../../Shared/Container";
import {
  AboutHeading,
  AboutSubHeading,
  InviteImageWrapper,
  PreScheduleWrapper,
  SocialIconWrapper,
  SocialSubtext,
  SocialText,
  SocialWrapper,
} from "../About/about.style";
// import { BuyButton } from "../../Shared/Button/Button.style";
import Telegram from "../../../assets/telegram.png";
import Twitter from "../../../assets/twitter.png";
import Discord from "../../../assets/discord.png";
import Invite from "../../../assets/invite.png";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser";

const InviteFriends = () => {
  /**@ts-ignore*/
  const [recipientMail, setRecipientMail] = useState<string>("");
  const [recipientName, setRecipientName] = useState<string>("");
  const [captcha, setCaptcha] = useState(false);
  const form = useRef();
  const recaptchaRef = React.useRef(null);

  useEffect(() => {}, [captcha]);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_e3zvz1e",
        "template_zlogn43",
        form.current,
        "DWl0xYmMi2OIxh3s-"
      )
      .then(
        (result) => {
          console.log(result.text);
          setRecipientName("");
          setRecipientMail("");
          setCaptcha(false);
          recaptchaRef.current.reset();
          alert("Thank you! Your email has been successfully sent.");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  console.log(captcha);

  function onChange(value) {
    console.log("Captcha value:", value);
    setCaptcha(value);
  }
  return (
    <>
      <PreSaleContainer>
        <div>
          <InviteWrapper>
            <div className="flex w-100 ">
              <InviteImageWrapper>
                <img className="img-contain" src={Invite} />
              </InviteImageWrapper>
              <div
                className="flex flex-col"
                style={{
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Heading className="invite">Invite your Friends</Heading>
                <InsideWrapper>
                  <form ref={form} onSubmit={sendEmail} className="form-data">
                    <InputWrapperName>
                      <InviteInputField
                        placeholder="Full Name"
                        onChange={(e) => {
                          setRecipientName(e.target.value);
                        }}
                        type="text"
                        name="user_name"
                        id="user_name"
                        value={recipientName}
                      />
                    </InputWrapperName>
                    <InputWrapper>
                      <InviteInputField
                        placeholder="Email address"
                        onChange={(e) => {
                          setRecipientMail(e.target.value);
                        }}
                        type="email"
                        name="user_email"
                        id="user_email"
                        value={recipientMail}
                      />
                      <BtnWrapper>
                        {captcha ? (
                          <SendButton type="submit" value="Send">
                            Send
                          </SendButton>
                        ) : (
                          <DisSendButton>Send</DisSendButton>
                        )}
                      </BtnWrapper>
                    </InputWrapper>
                  </form>
                </InsideWrapper>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ReCAPTCHA
                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                onChange={onChange}
                ref={recaptchaRef}
              />
            </div>
          </InviteWrapper>

          <PreScheduleWrapper>
            <AboutHeading> Join Blockstar Community</AboutHeading>
            <AboutSubHeading>
              community drives the success of the Protocol. Join the Community
              on Telegram, Twitter, and Discord to stay up to date on the latest
              community news. Write us at{" "}
              <a
                href={`mailto:info@blockstarcoin.com`}
                style={{ color: "aqua" }}
              >
                info@blockstarcoin.com
              </a>
              .
            </AboutSubHeading>

            <div className="flex  g-16 card-grid">
              <SocialWrapper>
                <div
                  className="w-full flex g-16 item-center"
                  onClick={() => {
                    window.open("https://t.me/joinblockstarcoin", "_blank");
                  }}
                >
                  <SocialIconWrapper>
                    <img
                      src={Telegram}
                      className="img-contain"
                      alt="telegram"
                    />
                  </SocialIconWrapper>

                  <div className="flex flex-col g-8 ">
                    <SocialText>Telegram</SocialText>
                    <SocialSubtext>
                      stay up to date on the latest community news
                    </SocialSubtext>
                  </div>
                </div>
              </SocialWrapper>
              <SocialWrapper>
                <div
                  className="w-full flex g-16 item-center"
                  onClick={() => {
                    window.open("https://twitter.com/BlockstarCoin", "_blank");
                  }}
                >
                  <SocialIconWrapper>
                    <img src={Twitter} className="img-contain" />
                  </SocialIconWrapper>

                  <div className="flex flex-col g-8 ">
                    <SocialText>Twitter</SocialText>
                    <SocialSubtext>
                      stay up to date on the latest community news
                    </SocialSubtext>
                  </div>
                </div>
              </SocialWrapper>
              <SocialWrapper>
                <div
                  className="w-full flex g-16 item-center"
                  onClick={() => {
                    window.open("https://discord.gg/tNExSxDn", "_blank");
                  }}
                >
                  <SocialIconWrapper>
                    <img src={Discord} className="img-contain" />
                  </SocialIconWrapper>

                  <div className="flex flex-col g-8 ">
                    <SocialText>Discord</SocialText>
                    <SocialSubtext>
                      stay up to date on the latest community news
                    </SocialSubtext>
                  </div>
                </div>
              </SocialWrapper>
            </div>
          </PreScheduleWrapper>
        </div>
      </PreSaleContainer>
    </>
  );
};

export default InviteFriends;
