const items = [
  {
    title: "NOV 2023 - JAN 2024",
    cardTitle: "Phase I",
    cardDetailedText: [
      "Research and Development",
      "Presale Preparation",
      "Prototype Development",
      "Presale Launch",
      "Web3 Website Launch",
      "Payment System Integration",
      "Telegram and Twitter Setup",
      "Community Engagement",
      "Marketing Campaigns",
    ],
    // cardDetailedText: `On 10 May 1940, Hitler began his long-awaited offensive in the west by invading neutral Holland and Belgium and attacking northern France. Holland capitulated after only five days of fighting, and the Belgians surrendered on 28 May. With the success of the German ‘Blitzkrieg’, the British Expeditionary Force and French troops were in danger of being cut off and destroyed.`,
  },
  {
    title: "(FEB - JUNE) 2024",
    cardTitle: "Phase II",
    cardDetailedText: [
      "Initial Development",
      "Start the initial development of the AI mining system",
      "Attract Investors",
      "Milestone Evaluation",
      "Official Coin Launch",
      "Marketing Efforts",
      "Partnerships",
    ],
  },
  {
    title: "(JULY - DEC) 2024",
    cardTitle: "Phase III",
    cardDetailedText: [
      "User Testing & Feedback",
      "Gather user feedback",
      "Implement suggested enhancements",
      "Analyze user behavior for optimization",
    ],
  },
  {
    title: "(JAN - JUNE) 2025",
    cardTitle: "Phase IV",
    cardDetailedText: [
      "Final Evaluation",
      "Evaluation of performance and security",
      "AI Mining System Launch",
      "Ensure the AI mining system is fully operational and efficient",
      "Continuous Improvement",
      "Ongoing development based on user feedback and market trend",
    ],
  },
  {
    title: "JULY 2025",
    cardTitle: "Phase V",
    cardDetailedText: [
      "Continuous Improvement (Ongoing)",
      "Expand features and functionalities",
      "Global expansion strategies",
      "Establish a dedicated customer support team",
      "Explore additional partnerships and alliances",
      "Research and development",
    ],
  },
  //   {
  //     title: "Beyond",
  //     cardTitle: "Phase VI",
  //     cardDetailedText: [
  //         •Continuous Improvement (Ongoing)
  //         •Expand features and functionalities
  //         •Global expansion strategies
  //         •Establish a dedicated customer
  //         support team
  //         •Explore additional partnerships and
  //         alliances
  //         •Research and development
  //     ],
  //   },
];

export default items;
