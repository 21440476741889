import styled from "styled-components";
import { MediaBreakpoints } from "../../../mediaQuery";

export const FooterText = styled.p`
  color: #fff;
  cursor: pointer;
  transistion: 0.5s;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  :hover {
    color: #1c274c;
  }
`;

export const FooteLogoContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (${MediaBreakpoints.mobile}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }
`;

export const LinkWrapper = styled.div`
  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
    justify-content: space-between;
  }
`;

export const FooterWrapper = styled.div`
  @media (${MediaBreakpoints.mobile}) {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  ${MediaBreakpoints.ipad} {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
`;

export const BottomWrapper = styled.div`
  flex-direction: column;
  gap: 8px;

  @media (${MediaBreakpoints.mobile}) {
    flex-direction: column;
    gap: 8px;
  }

  ${MediaBreakpoints.ipad} {
    flex-direction: column;
    gap: 8px;
  }
`;
