import styled from "styled-components";
// import Button from "@mui/material/Button";
import { BuyButton } from "../../Shared/Button/Button.style";
import { MediaBreakpoints } from "../../../mediaQuery";
export const AboutHeading = styled.p`
  color: #73fbfd;

  leading-trim: both;

  text-edge: cap;
  font-family: Inter;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 88px; /* 209.524% */
  letter-spacing: -1px;

  @media (${MediaBreakpoints.mobile}) {
    font-size: 24px;
    text-align: center;
    line-height: 34px;
  }

  ${MediaBreakpoints.ipad} {
    font-size: 24px;
    text-align: center;
  }
`;

export const AboutWrapper = styled.div`
  @media (${MediaBreakpoints.mobile}) {
    flex-direction: column;
    gap: 24px;
  }

  ${MediaBreakpoints.ipad} {
    flex-direction: column;
    gap: 24px;
  }
`;

export const AboutImageWrapper = styled.div`
  width: 100%;
  text-align: center;

  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
    display: none;
  }

  ${MediaBreakpoints.ipad} {
    width: 100%;
  }
`;

export const InviteImageWrapper = styled.div`
  width: 300px;
  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
    display: none;
  }

  ${MediaBreakpoints.ipad} {
    width: 100%;
  }
`;

export const LeftImageContainer = styled.div`
  width: 41%;

  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
  }

  ${MediaBreakpoints.ipad} {
    width: 100%;
  }
`;
export const RightContainer = styled.div`
  width: 48%;

  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  ${MediaBreakpoints.ipad} {
    width: 100%;
  }
`;

export const AboutSubHeading = styled.p`
  color: #b9c0d0;

  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.5px;
  text-align: justify;
  @media (${MediaBreakpoints.mobile}) {
    font-size: 15px !important;
    line-height: 26px;
    text-align: justify;
  }

  ${MediaBreakpoints.ipad} {
    font-size: 16px;
    text-align: center;
  }
`;

export const LearnButton = styled(BuyButton)`
  width: 200px !important;
`;

export const PreScheduleWrapper = styled.div`
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (${MediaBreakpoints.mobile}) {
    padding-top: 20px;
  }
`;

export const CardWrapper = styled.div`
  @media (${MediaBreakpoints.mobile}) {
    flex-direction: column;
    gap: 16px;
  }

  ${MediaBreakpoints.ipad} {
    flex-direction: column;
    gap: 16px;
  }
`;

export const ScheduleWrapper = styled.div`
  width: 100%;
  //   height: 60px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 10px;
  // background: linear-gradient(269deg, #fff -10.47%, #7aebf1 86.76%);
  background: #2d323a;
`;

export const PreScheduleHeading = styled.p`
  color: #fff;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media (${MediaBreakpoints.mobile}) {
    font-size: 16px;
    text-align: center;
  }

  ${MediaBreakpoints.ipad} {
    font-size: 16px;
    text-align: center;
  }
`;

export const PreScheduleHeading2 = styled.p`
  color: #fff;

  leading-trim: both;

  text-edge: cap;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media (${MediaBreakpoints.mobile}) {
    font-size: 16px;
    text-align: center;
  }

  ${MediaBreakpoints.ipad} {
    font-size: 16px;
    text-align: center;
  }
`;

export const PrescheduleContentWrapper = styled.div`
  @media (${MediaBreakpoints.mobile}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  ${MediaBreakpoints.ipad} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }
`;

export const PrescheduleLeftContent = styled.div`
  @media (${MediaBreakpoints.mobile}) {
    justify-content: center;
  }

  ${MediaBreakpoints.ipad} {
    justify-content: center;
  }
`;
export const PrescheduleRightContent = styled.div`
  @media (${MediaBreakpoints.mobile}) {
    justify-content: center;
  }

  ${MediaBreakpoints.ipad} {
    justify-content: center;
  }
`;

export const ScheduleDigit = styled.p`
  color: #fff;

  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const DistributionSubText = styled(ScheduleDigit)`
  font-size: 18px;
  @media (${MediaBreakpoints.mobile}) {
    font-size: 14px;
  }
`;
export const DistributionHeading = styled(ScheduleDigit)`
  font-size: 18px;
  @media (${MediaBreakpoints.mobile}) {
    font-size: 14px;
  }
`;

export const ScheduleDivider = styled.div`
  width: 100%;
  height: 1px;
  background: #fff;
`;

export const DistributionCard = styled.div`
  width: 100%;
  //   height: 70px;
  padding: 32px 30px;
  display: flex;
  align-items: center;
  // border-radius: 10px;
  border-radius: 14.063px;
  background: #2d323a;
  border: 0.703px solid #6cf0fd;
  // background: linear-gradient(269deg, #fff -10.47%, #7aebf1 86.76%);
  position: relative;
  z-index: 1;

  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
    padding: 22px 20px;
  }

  ${MediaBreakpoints.ipad} {
    width: 100%;
  }
`;

export const MultiCardWrapper = styled.div`
  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
  }

  ${MediaBreakpoints.ipad} {
    width: 100%;
  }
`;
export const StyledLogo = styled.div`
  align-items: center;
  margin-left: -85px;
  margin-right: -85px;

  padding: 16px;
`;

export const ScheduleFullDivider = styled.div`
  width: 100%;
  height: 1px;
  background: #7aebf1;
`;

export const SocialWrapper = styled.div`
  width: 450px;

  padding: 16px;
  border-radius: 10px;
  border: 1px solid #7aebf1;
  // background: #1c274c;
  background: #2d323a;
  cursor: pointer;
  box-shadow: 0px 4px 20px 0px rgba(122, 235, 241, 0.2);
`;

export const SocialIconWrapper = styled.div`
  width: 66px;
  height: 66px;
  overflow: hidden;
`;
export const SocialText = styled.p`
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const SocialSubtext = styled(SocialText)`
  font-size: 15px;
`;

export const invite = styled.div`
  @media (${MediaBreakpoints.mobile}) {
    display: unset;
  }
`;
