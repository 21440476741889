import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface User {
  ethBalance: number;
  usdtBalance: number;
  eth_usdt_balance: number;
  usdt_paid: number;
  token_balance: number;
  claimable_amount: number;
  vesting_amount: number;
  claimed_token: number;
  user_claimable_token: number;
}

const initialState: User = {
  /**@ts-ignore */
  ethBalance: null,
  /**@ts-ignore */
  usdtBalance: null,
  eth_usdt_balance: null,
  usdt_paid: null,
  token_balance: null,
  claimable_amount: null,
  vesting_amount: null,
  claimed_token: null,
  user_claimable_token: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setBalance(
      state,
      {
        payload,
      }: PayloadAction<{
        ethBalance: number;
        usdtBalance: number;
        eth_usdt_balance: number;
        usdt_paid: number;
        token_balance: number;
        claimable_amount: number;
        vesting_amount: number;
        claimed_token: number;
        user_claimable_token: number;
      }>
    ) {
      state.ethBalance = payload?.ethBalance;
      state.usdtBalance = payload?.usdtBalance;
      state.eth_usdt_balance = payload?.eth_usdt_balance;
      state.usdt_paid = payload?.usdt_paid;
      state.token_balance = payload?.token_balance;
      state.claimable_amount = payload?.claimable_amount;
      state.vesting_amount = payload?.vesting_amount;
      state.claimed_token = payload?.claimed_token;
      state.user_claimable_token = payload?.user_claimable_token;
      return state;
    },
  },
});

export const { setBalance } = userSlice.actions;

export default userSlice.reducer;
