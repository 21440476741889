import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
// import IconButton from "@mui/material/IconButton";
import * as React from "react";
import { BootstrapDialog } from "./modal.style";
import { useMobile } from "../../../mediaQuery/useDevice";
export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}
export interface closeProps {
  onClose: () => void;
}
const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,

        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
      {...other}
    >
      {children}
      {onClose ? <CloseIcon onClick={onClose} /> : null}
    </DialogTitle>
  );
};
const CloseIconScreen = (props: closeProps) => {
  const mobile = useMobile();
  return (
    <div className="header-wrap">
      <CloseIcon
        onClick={props.onClose}
        style={{ fontSize: mobile ? 25 : 30 }}
      />
    </div>
  );
};
interface modalProps {
  open: boolean;
  title: string;
  onClose: () => void;
  children: React.ReactNode;
  isHeader?: boolean;
  isFilterModal?: boolean;
}
const CustomizedDialogs = ({
  open,
  title,
  onClose,
  children,
  isHeader,
  isFilterModal,
}: modalProps) => {
  return (
    <>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        isFilterModal={isFilterModal}
      >
        {isHeader ? (
          <CloseIconScreen onClose={onClose} />
        ) : (
          <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
            {title}
          </BootstrapDialogTitle>
        )}

        <DialogContent dividers>{children}</DialogContent>
      </BootstrapDialog>
    </>
  );
};
export default CustomizedDialogs;
