import { useCallback } from "react";
import { InjectedConnector } from "@web3-react/injected-connector";
// import { hexlify } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { chainConfig } from "../constants/chain";
import { ETH_CHAIN_HEX } from "connectors";

export const useChangeAppChainId = () => {
  const { connector, library } = useWeb3React();
  return useCallback(
    async (newAppChainId: number) => {
      const isMetaMask = window.ethereum && window.ethereum.isMetaMask;

      try {
        if (connector instanceof InjectedConnector && isMetaMask) {
          const ethereum = window?.ethereum;
          await ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: ETH_CHAIN_HEX }],
          });
        }
        return true;
      } catch (err) {
        if (err.code === 4902) {
          const ethereum = window?.ethereum;
          await ethereum.request({
            method: "wallet_addEthereumChain",
            params: chainConfig?.[newAppChainId],
          });
        }
        console.log("Error in useChangeAppChainId(): ", err);
        return false;
      }
    },
    [connector, library]
  );
};
