import styled from "styled-components";
import { MediaBreakpoints } from "../../../mediaQuery";

export const Wrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
`;

export const Counter = styled.div`
  background: transparent;
  min-width: auto;
  display: flex;
  flex-direction: column;
  border-radius: 0;
  justify-content: center;
  align-items: center;
  width: 110px;
  position: relative;

  &:after {
    content: ":";
    position: absolute;
    right: -14px;
    color: #fff;
    font-weight: 700;
    font-size: 50px;
    top: 35%;
    transform: translateY(-50%);
  }
  &:last-child::after {
    display: none;
  }
`;

export const CountText = styled.p`
  text-transform: uppercase;
  font-size: 18px;
  padding-top: 10px;
  color: #fff;
  font-weight: 500;
  @media (${MediaBreakpoints.mobile}) {
    font-size: 16px;
    padding-top: 4px;
  }
`;

export const SpanText = styled.span`
  display: block;
  font-size: 40px;
  margin-top: 0;
  color: #73fbfd;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 4px;
  @media (${MediaBreakpoints.mobile}) {
    font-size: 30px;
  }
`;

export const CountDownHeading = styled.h1`
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media (${MediaBreakpoints.mobile}) {
    font-size: 18px;
  }
`;
