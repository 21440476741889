import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  openModal: boolean;
}

const initialState: AuthState = {
  openModal: false,
};

const authSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    setOpenModal(state, action: PayloadAction<boolean>) {
      state.openModal = action.payload;
    },
  },
});

export const { setOpenModal } = authSlice.actions;

export default authSlice.reducer;
