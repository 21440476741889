import { Interface } from "ethers";
import TOKEN_ABI from "../../config/ABI/ERC20.json";
import PRESALE_ABI from "../../config/ABI/PRESALE.json";

export const getTokenInterface = () => {
  return new Interface(TOKEN_ABI);
};

export const getPresaleInterface = () => {
  return new Interface(PRESALE_ABI);
};
