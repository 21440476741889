import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Presale {
  totalRaised: number;
  tokenSold: number;
  maxRaise: number;
  maxTokenSell: number;
  minimumBuyInEth: number;
  minimumBuyInUsdt: number;
  maximumBuy: number;
  presaleStatus: boolean;
  isCLaimEnable: boolean;
}

const initialState: Presale = {
  /**@ts-ignore */
  totalRaised: null,
  /**@ts-ignore */
  tokenSold: null,
  /**@ts-ignore */
  maxRaise: null,
  /**@ts-ignore */
  maxTokenSell: null,
  minimumBuyInEth: null,
  minimumBuyInUsdt: null,
  maximumBuy: null,
  presaleStatus: null,
  isCLaimEnable: null,
};

const presaleSlice = createSlice({
  name: "presale",
  initialState,
  reducers: {
    setPresale(
      state,
      {
        payload,
      }: PayloadAction<{
        totalRaised: number;
        tokenSold: number;
        maxRaise: number;
        maxTokenSell: number;
        minimumBuyInEth: number;
        minimumBuyInUsdt: number;
        maximumBuy: number;
        presaleStatus: boolean;
        isCLaimEnable: boolean;
      }>
    ) {
      state.totalRaised = payload?.totalRaised;
      state.tokenSold = payload?.tokenSold;
      state.maxRaise = payload?.maxRaise;
      state.maxTokenSell = payload?.maxTokenSell;
      state.minimumBuyInEth = payload?.minimumBuyInEth;
      state.minimumBuyInUsdt = payload?.minimumBuyInUsdt;
      state.maximumBuy = payload?.maximumBuy;
      state.presaleStatus = payload?.presaleStatus;
      state.isCLaimEnable = payload?.isCLaimEnable;

      return state;
    },
  },
});

export const { setPresale } = presaleSlice.actions;

export default presaleSlice.reducer;
