import React from "react";
import {
  Heading,
  HeroWrapper,
  LeftContainer,
  RightContainer,
  Subheading,
} from "./hero.style";
import Navbar from "../../Navbar";
import PreSaleContainer from "../../Shared/Container";

import Swapper from "../../Swap";
// import { LearnButton } from "../About/about.style";

const Hero = () => {
  return (
    <>
      <Navbar />
      <div className="hero-img">
        <PreSaleContainer>
          <HeroWrapper className="w-full flex justify-between items-center">
            <LeftContainer className="flex flex-col item-start">
              <Heading>
                Welcome to
                <br /> BlockStar Coin -
                <span style={{ color: "#73FBFD" }}>The Future of Mining</span>
              </Heading>
              <Subheading>
                Are you ready to embark on a new era of cryptocurrency mining?
                At BlockStar Coin, we're transforming the mining landscape with
                our innovative AI Mining System. We're not just a
                cryptocurrency; we're a revolution, and we're inviting you to be
                a part of it!
              </Subheading>

              {/* <LearnButton>Read More</LearnButton> */}
            </LeftContainer>
            <RightContainer className="flex justify-end">
              <Swapper />
            </RightContainer>
          </HeroWrapper>
        </PreSaleContainer>
      </div>
    </>
  );
};

export default Hero;
