import React from "react";
import {
  DistributionCard,
  DistributionHeading,
  DistributionSubText,
} from "./about.style";
import ThinProgress from "../../Shared/LinearProgress/thinProgress";

interface CardProps {
  distributionPercentage: number;
  distributionType: string;
  distributionAmount: number;
}

const Card = ({
  distributionPercentage,
  distributionType,
  distributionAmount,
}: CardProps) => {
  return (
    <DistributionCard>
      <div className="w-full flex flex-col g-8">
        <DistributionHeading>{distributionPercentage}%</DistributionHeading>
        <div className="w-full flex justify-between">
          <DistributionSubText>{distributionType}</DistributionSubText>
          <DistributionSubText>{distributionAmount}</DistributionSubText>
        </div>
        <ThinProgress percentage={distributionPercentage} />
      </div>
    </DistributionCard>
  );
};

export default Card;
