import React from "react";
import PreSaleContainer from "../../Shared/Container";
import { PreScheduleWrapper, ScheduleFullDivider } from "../About/about.style";
import { LogoWrapper } from "../../Navbar/navbar.style";
import Logo from "../../../assets/BLog.png";
import { AddressText, MiniAddressText } from "../ContractAddress/address.style";
import {
  // BottomWrapper,
  FooteLogoContent,
  // FooterText,
  FooterWrapper,
  LinkWrapper,
} from "./footer.style";
import { blockStarContract } from "../../../config";
// import PresaleCounteDown from "components/Shared/Counter";
// import Countdown from "react-countdown";

const Footer = () => {
  // const targetDate = new Date("2024-02-07T23:59:59");
  // const renderer = ({ days, hours, minutes, completed }: any) => {
  //   if (completed) {
  //     console.log("complted");
  //     // <h1>PreSlae Completed</h1>
  //   } else {
  //     return <PresaleCounteDown days={days} hours={hours} minutes={minutes} />;
  //   }
  // };

  return (
    <>
      <PreSaleContainer>
        <PreScheduleWrapper style={{ padding: "60px 0" }}>
          <ScheduleFullDivider />

          <FooterWrapper className="flex w-full justify-between footer-mb ">
            <FooteLogoContent>
              <LogoWrapper className="logo-contain">
                <img src={Logo} />
              </LogoWrapper>
              <div className="flex flex-col g-8">
                <AddressText style={{ color: "#fff" }} className="address">
                  CONTRACT ADDRESS
                </AddressText>

                <MiniAddressText style={{ color: "#fff" }}>
                  {blockStarContract}
                </MiniAddressText>
              </div>
            </FooteLogoContent>

            <LinkWrapper className="flex g-24 item-center">
              {/* <div className="flex flex-col justify-start item-start g-16">
                <FooterText>Home</FooterText>
                <FooterText>Contact</FooterText>
              </div>
              <div className="flex flex-col justify-start item-start g-16">
                <FooterText>Presale</FooterText>
                <FooterText>Team</FooterText>
              </div>
             */}
              {/* <PresaleCounteDown days={13} hours={2} minutes={30} />; */}
              {/* <Countdown date={targetDate} renderer={renderer} /> */}
              {/* <CounteDown /> */}
              <div className="flex flex-col g-8 w-100 release-date">
                <AddressText style={{ color: "#fff", whiteSpace: "nowrap" }}>
                  AI Mining Release Date: Jan 2025
                </AddressText>
              </div>
            </LinkWrapper>
          </FooterWrapper>

          {/* <ScheduleFullDivider /> */}

          {/* <BottomWrapper className="flex justify-between w-full">
            <FooterText>All Rights Reserve</FooterText>
            <FooterText>Terms & Conditions. Privacy Policy</FooterText>
          </BottomWrapper> */}
        </PreScheduleWrapper>
      </PreSaleContainer>
    </>
  );
};

export default Footer;
