import * as React from "react";
import { styled } from "@mui/material/styles";
// import Box from "@mui/material/Box";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { ProgressText } from "./style";
import { round } from "lodash";

const BorderLinearProgress = styled(LinearProgress)(() => ({
  width: "100%",
  height: 24,
  borderRadius: 20,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "rgba(28, 39, 76, 0.80)",
    //   theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 24,
    background: "#73FBFD",
    //  "linear-gradient(269deg, #7AEBF1 -10.47%, #1C274C 86.76%)",
    // theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

interface ScheduleProps {
  percentage: number;
}

const SheduleProgressBar = ({ percentage }: ScheduleProps) => {
  return (
    <>
      <Box
        position="relative"
        display="inline-flex"
        style={{ width: "100%", color: "red" }}
      >
        <BorderLinearProgress variant="determinate" value={percentage} />

        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <ProgressText isAboveSixtyPercent={percentage > 60}>
            {" "}
            {round(percentage, 4)}%
          </ProgressText>

          {/* <WhiteTextTypography variant="body2">{`${value}%`}</WhiteTextTypography> */}
        </Box>
      </Box>
    </>
  );
};

export default SheduleProgressBar;
