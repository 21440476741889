import React from "react";
// import FileCopyIcon from "@mui/icons-material/FileCopy";
import {
  AddressText,
  AddressWrapper,
  ContractBtnWrapper,
  MainWrapper,
  MiniAddressText,
  TokenWrapper,
  VerticalDivider,
} from "./address.style";
import PreSaleContainer from "../../Shared/Container";
import Token from "../../../assets/LargeLogo.png";
import PresaleButton from "../../Shared/Button";
import copy from "copy-to-clipboard";
import { useMobile } from "../../../mediaQuery/useDevice";
import { blockStarContract } from "../../../config";

const ShowContaractAddress = () => {
  const handleCopy = () => {
    copy(blockStarContract);
  };

  const mobile = useMobile();
  return (
    <>
      <div className="m-90">
        <PreSaleContainer>
          <MainWrapper>
            <AddressWrapper>
              <div className="flex w-full g-8 item-center">
                <TokenWrapper>
                  <img src={Token} className="img-contain" />
                </TokenWrapper>
                <div className="flex flex-col gap-8">
                  <AddressText>CONTRACT ADDRESS</AddressText>

                  <MiniAddressText>{blockStarContract}</MiniAddressText>
                </div>
              </div>
              {!mobile && <VerticalDivider />}
              <ContractBtnWrapper className="flex g-16 w-full justify-between">
                {!mobile && (
                  <div className="flex flex-col g-8">
                    <AddressText>NETWORK</AddressText>

                    <MiniAddressText>Ethereum ERC-20</MiniAddressText>
                  </div>
                )}

                <PresaleButton label="Copy Address" handleClick={handleCopy} />
              </ContractBtnWrapper>
            </AddressWrapper>
          </MainWrapper>
        </PreSaleContainer>
      </div>
    </>
  );
};

export default ShowContaractAddress;
