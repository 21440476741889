import useSWR from "swr";
import getEthPrice from "../utilities/getEthPrice";

const useGetEthPrice = () => {
  const { data } = useSWR(`eth`, () => getEthPrice(), {
    refreshInterval: 60000,
  });

  return data;
};

export default useGetEthPrice;
