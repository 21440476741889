import { toast } from "react-toastify";

export const toastSuccessHandler = (message: string) => {
  toast.success(message, {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    style: {
      fontSize: "18px",
    },
  });
};

export const toastErrorHandler = (message: string, data: string) => {
  toast.error(message, {
    data: data,
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    style: {
      fontSize: "18px",
    },
  });
};
