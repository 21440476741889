import { InjectedConnector } from "@web3-react/injected-connector";

export const ETH_CHAIN = 1;

export const ETH_CHAIN_HEX = "0x1";

const supportedChainIds: number[] = [ETH_CHAIN];

export const injected = new InjectedConnector({
  supportedChainIds,
});
