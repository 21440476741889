import * as React from "react";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
// import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { StyledTab, TabWrapper } from "./tab.style";
import { ReactComponent as Eth } from "../../../assets/eth.svg";

import { ReactComponent as TokenLogo } from "../../../assets/tether.svg";
import { BUY_CURRENCY } from "../../Swap";
// interface TabPanelProps {
//   children?: React.ReactNode;
//   index: number;
//   value: number;
// }

// function CustomTabPanel(props: TabPanelProps) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box sx={{ p: 3 }}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface SwapTabProps {
  selectedCurrency: BUY_CURRENCY;
  setSelectedCurrency: (selectedCurrency: BUY_CURRENCY) => void;
}

const SwapTab = ({ selectedCurrency, setSelectedCurrency }: SwapTabProps) => {
  // const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setValue(newValue);

  //   console.log(newValue);
  // };

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <TabWrapper
          value={selectedCurrency}
          // onChange={handleChange}
          aria-label="basic tabs example"
        >
          <StyledTab
            label="BUY WITH ETH"
            icon={<Eth />}
            iconPosition="start"
            className={
              selectedCurrency === BUY_CURRENCY.ETH
                ? "hoverEffect active"
                : "hoverEffect"
            }
            {...a11yProps(0)}
            isActive={selectedCurrency === BUY_CURRENCY.ETH}
            onClick={() => setSelectedCurrency(BUY_CURRENCY.ETH)}
          />

          <StyledTab
            label="BUY WITH USDT"
            className={
              selectedCurrency === BUY_CURRENCY.USDT
                ? " active mr-10"
                : " mr-10"
            }
            icon={<TokenLogo />}
            iconPosition="start"
            {...a11yProps(1)}
            isActive={selectedCurrency === BUY_CURRENCY.USDT}
            onClick={() => setSelectedCurrency(BUY_CURRENCY.USDT)}
          />
        </TabWrapper>
      </Box>
    </Box>
  );
};

export default SwapTab;
