import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMulticallPresale } from "../../utilities/getMulticallPresaleData";
import { setPresale } from ".";
import { AppState } from "..";
import { ETH_CHAIN } from "connectors";

export const useSetPresale = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const getMulticallData = async () => {
      try {
        const data = await getMulticallPresale(ETH_CHAIN);
        dispatch(
          setPresale({
            totalRaised: data?.["amountRaised"] + data?.["amountRaisedETHUSDT"],
            tokenSold: data?.["soldToken"],
            maxTokenSell: data?.["totalSupply"],
            maxRaise: data?.["totalSupply"] * 0.03,
            minimumBuyInEth: data?.["minimumBuyInEth"],
            minimumBuyInUsdt: data?.["minimumBuyInUsdt"],
            maximumBuy: data?.["maximumBuy"],
            presaleStatus: data?.["presaleStatus"] ? true : false,
            isCLaimEnable: data?.["enableClaim"] ? true : false,
          })
        );
      } catch (err) {
        if (err instanceof Error) {
          console.log("err", err?.message);
        }
      }
    };

    getMulticallData();
  }, [dispatch]);
};

export const usePresale = () => {
  return useSelector((state: AppState) => state.presale);
};
