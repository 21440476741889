export const blockStarContract = "0xdFBa1613b408F55b976D61087056FCaA477bb0aF";

export const presaleContract = "0x3741E3eafaD599041f703cDc07EF275aA86d6129"
// export const presaleContract = "0xe59236675fb003ae250c09f35623a72870a1e9a0";

export const ethRpcUrl = "https://1rpc.io/eth";

export const multicallAddress = "0xeefba1e63905ef1d7acba5a8513c70307c1ce441";

export const usdtAddress = "0xdAC17F958D2ee523a2206206994597C13D831ec7";
