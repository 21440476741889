import { useState, useEffect, useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import { InjectedConnector } from "@web3-react/injected-connector";
import { ETH_CHAIN, injected } from "../connectors/index";
import { useChangeAppChainId } from "./useSwitchNetwork";

export const useDisconnect = () => {
  const { connector, deactivate } = useWeb3React();

  return useCallback(() => {
    if (connector instanceof InjectedConnector && window.ethereum.isMetaMask) {
      deactivate();
      return;
    }
    deactivate();
  }, [connector, deactivate]);
};

export function useWeb3EagerConnect() {
  const { activate, active } = useWeb3React();

  const [tried, setTried] = useState(false);
  const changeChainId = useChangeAppChainId();

  useEffect(() => {
    async function connectwallet() {
      await activate(injected).catch(() => {
        changeChainId(ETH_CHAIN);
        console.log("error in metamask");
      });
    }
    connectwallet();
  }, [activate, changeChainId]);

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (!tried && active) {
      setTried(true);
    }
  }, [tried, active]);

  return tried;
}

export function useInactiveListener(suppress = false) {
  const { active, error, activate, chainId } = useWeb3React();

  useEffect(() => {
    const { ethereum } = window as any;
    if (ethereum && ethereum.on && !active && !error && !suppress) {
      const handleConnect = () => {
        activate(injected);
      };
      // eslint-disable-next-line
      const handleChainChanged = (chainId: string | number) => {
        console.log(chainId);
        activate(injected);
      };
      const handleAccountsChanged = (accounts: string[]) => {
        if (accounts.length > 0) {
          activate(injected);
        }
      };

      ethereum.on("connect", handleConnect);
      ethereum.on("chainChanged", handleChainChanged);
      ethereum.on("accountsChanged", handleAccountsChanged);

      if (ethereum.removeListener) {
        ethereum.removeListener("connect", handleConnect);
        ethereum.removeListener("chainChanged", handleChainChanged);
        ethereum.removeListener("accountsChanged", handleAccountsChanged);
      }
    }
  }, [active, error, suppress, activate, chainId]);
}
