import { useCallback, useState } from "react";
// import { gasPrice } from "../utilities";
import { useWeb3React } from "@web3-react/core";
import { BigNumber } from "@ethersproject/bignumber";
import { toastErrorHandler, toastSuccessHandler } from "../utilities/toast";
import { getPresaleInterface } from "../utilities/contract/Interface";
import { getPresaleCallData } from "../utilities/contract/encode";
import { presaleContract } from "../config";

// approval transactions
export enum BuyTransactionStatus {
  /** on loading */
  BUY_LOADING,
  /** on confirmed */
  BUY_CONFIRMED,
  /** on error */
  BUY_ERROR,
}

export interface BuyResponse {
  /** transaction status */
  buyTxStatus: BuyTransactionStatus;
  /** approval callback function */
  triggeredBuy: (buyWithUSDT: boolean, amount: BigNumber) => Promise<void>;
  /** tx */
  tx?: boolean;
}

export const useBuy = (): BuyResponse => {
  // to check if approval required

  const [tx, setTx] = useState<boolean>(false);

  let [buyTxStatus, setBuyTxStatus] = useState<BuyTransactionStatus>();
  const { account, library } = useWeb3React();

  const iface = getPresaleInterface();

  const triggeredBuy = useCallback(
    async (buyWithUSDT: boolean, amount: BigNumber) => {
      try {
        if (!amount) return null;
        // set approval status loading

        setBuyTxStatus(BuyTransactionStatus.BUY_LOADING);
        console.log({ amount: amount?.toString() });

        const callData = buyWithUSDT
          ? getPresaleCallData(iface, "buyTokenUSDT", [amount?.toString()])
          : getPresaleCallData(iface, "buyToken", []);
        // estimate gas on
        let gasLimit = await library.getSigner().estimateGas({
          from: account,
          to: presaleContract,
          data: callData,
          value: buyWithUSDT ? 0 : amount,
        });

        // gas price
        // let gas_price = await gasPrice(library);

        const approve = await library.getSigner().sendTransaction({
          from: account,
          to: presaleContract,
          data: callData,
          gasLimit,
          // gasPrice: gas_price,
          value: buyWithUSDT ? 0 : amount,
        });

        // waiting atleast two confirmation
        await approve.wait(2);
        setTx(approve?.transactionHash);
        // set approval transaction status to confirm
        setBuyTxStatus(BuyTransactionStatus.BUY_CONFIRMED);

        toastSuccessHandler("Buy successful");
        window.location.reload();
      } catch (err) {
        console.log("useBuy Failed", err);
        // set error
        setBuyTxStatus(BuyTransactionStatus.BUY_ERROR);
        toastErrorHandler("Buy Error", err?.message?.slice(0, 20));
      }
    },
    [library, account, iface]
  );

  return {
    /** @ts-ignore */
    buyTxStatus,
    /** @ts-ignore */
    triggeredBuy,
    tx,
  };
};
