import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import { MediaBreakpoints } from "../../../mediaQuery";

type dialogProps = {
  isFilterModal?: boolean;
};

export const BootstrapDialog = styled(Dialog)<dialogProps>(({ theme }) => ({
  "& .MuiDialog-container": {
    [`@media(${MediaBreakpoints.mobile})`]: {
      height: "auto",
    },
  },

  "& .header-wrap": {
    position: "absolute",
    top: 24,
    right: 30,
    zIndex: 1,
    "&:hover": {
      cursor: "pointer",
    },
  },
  "& .MuiDialog-paperWidthSm": {
    borderRadius: 25,
    maxWidth: "100%",
    // backgroundColor: "#1D2A40",
    backgroundColor: "#1C274C",
    transform: "scale(0.9)",
    boxShadow: "0px 0px 20px 0px rgba(122, 235, 241, 0.50)",
    color: "#7AEBF1",
    [`@media(${MediaBreakpoints.mobile})`]: {
      height: "100vh",
      margin: 0,
      borderRadius: 0,
      width: "100%",
      transform: "scale(1.0)",
    },
  },
  "& .MuiButtonBase-root": {
    color: "#7AEBF1",
  },
  "& .MuiDialogContent-root": {
    padding: "18px 32px",

    [`@media(${MediaBreakpoints.mobile})`]: {
      padding: "18px 16px",
      // padding: isFilterModal ? "0" : "18px 16px",
    },
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialogTitle-root": {
    boxShadow: "unset",
    fontWeight: 700,
    fontSize: 25,
    textTransform: "capitalize",
    padding: "18px 32px",
    color: "#7AEBF1",
    [`@media(${MediaBreakpoints.mobile})`]: {
      fontSize: 20,
      padding: "18px 16px",
    },
    "& svg": {
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
}));
export const BootstrapMobileDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-container": {},

  "& .header-wrap": {
    position: "absolute",
    top: 24,
    right: 30,
    zIndex: 1,
    "&:hover": {
      cursor: "pointer",
    },
  },
  "& .MuiDialog-paperWidthSm": {
    borderRadius: 25,
    maxWidth: "100%",
    backgroundColor: "var(--modal-background-color)",
    color: "var(--offercard-text-color)",
    boxShadow:
      "0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)",
  },
  "& .MuiButtonBase-root": {
    color: "#7AEBF1",
  },
  "& .MuiDialogContent-root": {
    padding: 9,
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialogTitle-root": {
    boxShadow: "unset",
    fontWeight: 700,
    fontSize: 25,
    textTransform: "capitalize",
    padding: 9,
    color: "#7AEBF1",
    [`@media(${MediaBreakpoints.mobile})`]: {
      fontSize: 16,
    },
    "& svg": {
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
}));
