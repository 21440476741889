import { aggregate, ICall, IResponse } from "@makerdao/multicall";
import { isEmpty } from "lodash";
import { ethRpcUrl, multicallAddress } from "../config";

const multicall = async (calls: ICall[]): Promise<IResponse> => {
  if (isEmpty(calls)) return null;
  const results = await aggregate(calls, {
    rpcUrl: ethRpcUrl,
    multicallAddress: multicallAddress,
  });

  return results as IResponse;
};

export default multicall;
