import { useWeb3React } from "@web3-react/core";
import { Contract } from "ethers";
import { useMemo } from "react";
import TOKEN_ABI from "../config/ABI/ERC20.json";
import PRESALE_ABI from "../config/ABI/PRESALE.json";

export const useContract = (ABI: any, address: string) => {
  const { library, chainId } = useWeb3React();
  return useMemo(() => {
    if (!address || !library || !chainId) return null;
    return new Contract(address, ABI, library);
  }, [ABI, address, library, chainId]);
};

export const useTokenContract = (address: string) => {
  return useContract(TOKEN_ABI, address);
};

export const usePresaleContract = (address: string) => {
  return useContract(PRESALE_ABI, address);
};
