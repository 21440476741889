import React from "react";
import PreSaleContainer from "../../Shared/Container";
import AboutImage from "../../../assets/about.png";
import Image1 from "../../../assets/image-1.png";
import Image3 from "../../../assets/image-3.png";
import Image2 from "../../../assets/image-2.png";
import Image4 from "../../../assets/image-4.png";
import MainLogo from "../../../assets/mainlogo.png";
import Icon1 from "../../../assets/icon1.png";
import Icon2 from "../../../assets/icon2.png";
import Icon3 from "../../../assets/icon3.png";
import Icon4 from "../../../assets/icon4.png";
import Icon5 from "../../../assets/icon5.png";
import Icon6 from "../../../assets/icon6.png";

import Token from "../../../assets/token.png";
import CircleIcon from "@mui/icons-material/Circle";
import TokenImg from "../../../assets/token-img.png";

// import Roadmap from "../../../assets/bRoadmap.png";
import {
  AboutHeading,
  AboutImageWrapper,
  AboutSubHeading,
  AboutWrapper,
  CardWrapper,
  // DistributionCard,
  // DistributionHeading,
  // DistributionSubText,
  LearnButton,
  LeftImageContainer,
  MultiCardWrapper,
  // PreScheduleHeading,
  PreScheduleHeading2,
  PreScheduleWrapper,
  PrescheduleContentWrapper,
  PrescheduleLeftContent,
  PrescheduleRightContent,
  RightContainer,
  ScheduleDigit,
  ScheduleDivider,
  ScheduleFullDivider,
  ScheduleWrapper,
  // SocialIconWrapper,
  // SocialSubtext,
  // SocialText,
  // SocialWrapper,
  StyledLogo,
} from "./about.style";

import { LiveButton, TokenWrapper } from "../Hero/hero.style";
// import ProgressBar from "../../Shared/LinearProgress";
import SheduleProgressBar from "../../Shared/LinearProgress/scheduleProgress";
import { AddressText, MiniAddressText } from "../ContractAddress/address.style";
// import LargeLogo from "../../../assets/LargeLogo.png";
import Whitepaper from "../../../assets/whitepaper.pdf";
// import ThinProgress from "../../Shared/LinearProgress/thinProgress";
import Card from "./card";

import {
  useIpad,
  useMobile,
  useMobile2,
  useMobile3,
} from "../../../mediaQuery/useDevice";
import { Chrono } from "react-chrono";
import items from "../../../constants/roadmap";

// const Items = [
//   {
//     title: "May 1940",
//     cardTitle: "Dunkirk",
//     cardSubtitle:
//       "Men of the British Expeditionary Force (BEF) wade out to a destroyer during the evacuation from Dunkirk.",
//     cardDetailedText: ["paragraph1", "paragraph2"],
//     timelineContent: <div>Custom content</div>,
//   },
// ];

import { usePresale } from "../../../store/presale/hook";
// import MidSectionTabs from "components/Shared/Tab/mid-section-tab";
import ShowContaractAddress from "../ContractAddress";
// import MidSectionTabs from "components/Shared/Tab/mid-section-tab";
const About = () => {
  const mobile = useMobile();
  const mobile2 = useMobile2();
  const mobile3 = useMobile3();
  const ipad = useIpad();

  const isitWeb = mobile || mobile2 || mobile3 || ipad;

  const presale = usePresale();

  const benifits = [
    {
      id: 1,
      desc: `AI-powered mining: BlockStar Coin uses a state-of-the-art AI mining system that is more efficient and profitable than traditional mining methods.`,
    },
    {
      id: 2,
      desc: `Low energy consumption: BlockStar Coin's AI mining system consumes less energy than traditional mining methods, making it more environmentally friendly.`,
    },
    {
      id: 3,
      desc: `Easy to use: BlockStar Coin's AI mining system is easy to use, even for beginners.`,
    },
    {
      id: 4,
      desc: `Secure: BlockStar Coin's AI mining system is secure and protected from cyberattacks.`,
    },
  ];

  return (
    <>
      <div
        style={{
          background: "rgba(0,2,4,255)",
        }}
      >
        <ShowContaractAddress />
        <PreSaleContainer>
          <AboutWrapper
            className="w-full flex justify-between item-center p-60"
            id="why-choose-us"
          >
            <LeftImageContainer>
              <AboutImageWrapper className="img-wrapper1">
                <img className="img-contain2" src={AboutImage} />
              </AboutImageWrapper>
            </LeftImageContainer>

            <RightContainer className="flex flex-col g-24">
              <AboutHeading>Why Choose BlockStar Coin ?</AboutHeading>

              <AboutSubHeading>
                Annual Profit Sharing: At BlockStar Coin, we aim to provide
                investors with a lucrative and expedient mining experience while
                ensuring all coin holders benefit from our annual profitsharing
                plan. It's simple - the longer you hold, the more you earn.
                Holders of 3 months will receive a 5% profit, 6 months will
                enjoy a 10% profit, 9 months will be rewarded with a 15% profit,
                and a remarkable 25% annually for those who hold long-term.
              </AboutSubHeading>
              <AboutSubHeading>
                Presale Season: Get in on the action during our presale season,
                where you can buy BlockStar Coin at a whopping 50% discount off
                the launch price. Don't miss out on this incredible opportunity.
              </AboutSubHeading>
              <AboutSubHeading>
                AI-Powered Mining: Our cutting-edge AI Mining System ensures the
                most efficient and profitable mining experience. We constantly
                analyze market trends to identify the most lucrative mining
                opportunities, so you can maximize your returns.
              </AboutSubHeading>
              <AboutSubHeading>
                With AI-powered mining, exclusive discounts during the presale,
                and the opportunity for annual profit sharing, BlockStar Coin is
                your gateway to a bright future in the world of
                cryptocurrencies. Join us today and become part of the next
                generation of mining.
              </AboutSubHeading>
            </RightContainer>
          </AboutWrapper>
          <AboutWrapper className="w-full flex justify-between item-center m-40 p-60">
            <RightContainer className="flex flex-col g-24 ">
              <AboutHeading>Invest with Confidence</AboutHeading>
              <AboutSubHeading>
                BlockStar Coin is not just another coin; it's a promise of
                innovation, profitability, and growth. Our commitment to
                transparency, security, and sustainability makes us a
                trustworthy choice for both seasoned investors and newcomers to
                the crypto world.
              </AboutSubHeading>
            </RightContainer>
            <LeftImageContainer>
              <AboutImageWrapper>
                <img className="img-contain2" src={Image1} />
              </AboutImageWrapper>
            </LeftImageContainer>
          </AboutWrapper>
          <AboutWrapper
            className="w-full flex justify-between item-center shade"
            id="whitepaper"
          >
            <LeftImageContainer>
              <AboutImageWrapper>
                <img className="img-contain2" src={Image2} />
              </AboutImageWrapper>
            </LeftImageContainer>

            <RightContainer className="flex flex-col g-24 img-wrapper2">
              <AboutHeading>Whitepaper</AboutHeading>
              <AboutSubHeading>
                Our whitepaper is your go-to resource for a comprehensive
                understanding of the BlockStar Coin project. It covers every
                aspect, from technical details to our long-term vision. .
              </AboutSubHeading>

              <a href={Whitepaper} download="Whitepaper" target="_blank">
                <LearnButton>Download</LearnButton>
              </a>
            </RightContainer>
          </AboutWrapper>
          <AboutWrapper
            className="w-full flex justify-between item-center  p-60"
            id="benefits"
          >
            <RightContainer className="flex flex-col g-24 m-40 pr-40">
              <AboutHeading>Benefits of BlockStar Coin</AboutHeading>
              {benifits.map((bnt) => (
                <div className="flex item-start g-4">
                  <CircleIcon
                    style={{
                      fontSize: "12px",
                      color: "#fff",
                      marginTop: "7px",
                    }}
                  />

                  <AboutSubHeading style={{ fontSize: "18px" }}>
                    {bnt.desc}
                  </AboutSubHeading>
                </div>
              ))}
            </RightContainer>
            <LeftImageContainer>
              <AboutImageWrapper>
                <img className="img-contain2" src={Image3} />
              </AboutImageWrapper>
            </LeftImageContainer>
          </AboutWrapper>
          <AboutWrapper
            className="w-full flex justify-between item-center shade"
            id="about-us"
          >
            <LeftImageContainer>
              <AboutImageWrapper>
                <img className="img-contain2" src={Image4} />
              </AboutImageWrapper>
            </LeftImageContainer>

            <RightContainer className="flex flex-col g-24 img-wrapper2">
              <AboutHeading>About Us</AboutHeading>
              <AboutSubHeading>
                At BlockStar Coin, we are the forefront of revolutionizing the
                cryptocurrency landscape through the integration of cutting-edge
                artificial intelligence (AI) technology. With a team of
                visionary experts and innovators, we are dedicated to reshaping
                the future of digital currency and empowering investors with
                unparalleled opportunities for growth and profit. Our
                overarching objective is to establish a secure and lucrative
                investment, enriched by innovative AI-driven tools that offer
                profound market insights and ensure coin stability. Our
                innovation guarantees an annual profit of 25% for BlockStar Coin
                holders, made possible through staking, fee sharing, and yield
                farming.
              </AboutSubHeading>
            </RightContainer>
          </AboutWrapper>
        </PreSaleContainer>

        <PreSaleContainer>
          <div className="">
            <div className="flex flex-col g-24">
              <PreScheduleWrapper>
                <AboutHeading style={{ color: "#fff" }}>
                  PreSale Schedule
                </AboutHeading>
                <AboutSubHeading>
                  Journey that not only gets you ahead but takes you through 7
                  stages of opportunity. By participating in a presale, you're
                  not only getting a head start but also showing your belief in
                  the project's potential. It's your chance to be part of the
                  inner circle, securing your place as an early adopter and
                  potentially reaping the rewards as the project gains momentum.
                </AboutSubHeading>
              </PreScheduleWrapper>
              <ScheduleWrapper className="spark">
                <div className="flex justify-between w-full">
                  <PreScheduleHeading2>BLKS Remaining</PreScheduleHeading2>
                  {presale?.tokenSold === presale?.maxTokenSell && (
                    <LiveButton>Sold Out</LiveButton>
                  )}
                </div>
                <div className="w-full flex flex-col g-8">
                  <SheduleProgressBar
                    percentage={
                      (presale?.tokenSold / presale?.maxTokenSell) * 100
                    }
                  />
                  <div className="w-full flex justify-end">
                    <ScheduleDigit>
                      ${presale?.totalRaised}/${presale?.maxRaise}
                    </ScheduleDigit>
                  </div>
                </div>
                <ScheduleDivider />

                <PrescheduleContentWrapper className="w-full flex justify-between">
                  <PrescheduleLeftContent className="flex w-full g-8 item-start">
                    <TokenWrapper>
                      <img src={Token} className="img-contain" />
                    </TokenWrapper>
                    <div className="flex flex-col gap-8">
                      <AddressText>{presale.maxTokenSell}</AddressText>
                      <MiniAddressText>BLKS Allocation</MiniAddressText>
                    </div>
                  </PrescheduleLeftContent>
                  <PrescheduleRightContent className="flex w-full g-8 justify-end">
                    <div className="flex flex-col gap-8">
                      <AddressText> $0.03</AddressText>
                      <MiniAddressText>Purchace Price</MiniAddressText>
                    </div>
                  </PrescheduleRightContent>
                </PrescheduleContentWrapper>
              </ScheduleWrapper>
            </div>
            <div className="flex flex-col g-24">
              <PreScheduleWrapper
                style={{ color: "#fff", textAlign: "center" }}
              >
                <AboutHeading style={{ color: "#fff" }}>
                  Token distribution
                </AboutHeading>
                <AboutSubHeading style={{ textAlign: "center" }}>
                  based on Max Supply.
                </AboutSubHeading>
              </PreScheduleWrapper>

              <CardWrapper className="flex w-full item-center justify-between">
                <MultiCardWrapper
                  className="flex flex-col g-40 MultiCardWrapper-90 card-width "
                  style={{ width: "33%" }}
                >
                  <Card
                    distributionPercentage={10}
                    distributionType="LAUNCH"
                    distributionAmount={(5000000000 * 10) / 100}
                  />
                  <Card
                    distributionPercentage={10}
                    distributionType="RESERVE FUND"
                    distributionAmount={(5000000000 * 10) / 100}
                  />
                  <Card
                    distributionPercentage={2}
                    distributionType="AIRDROPS/BOUNTIES"
                    distributionAmount={(5000000000 * 2) / 100}
                  />
                  <Card
                    distributionPercentage={7}
                    distributionType="STAKING/LIQUIDITY POOLS"
                    distributionAmount={(5000000000 * 7) / 100}
                  />
                </MultiCardWrapper>

                {!isitWeb && (
                  <div style={{ width: "28%" }}>
                    <div style={{ transform: "translateY(222px)" }}>
                      <Card
                        distributionPercentage={30}
                        distributionType="PRESALE"
                        distributionAmount={(5000000000 * 30) / 100}
                      />
                    </div>
                    <StyledLogo>
                      <img
                        src={TokenImg}
                        className="img-token"
                        style={{
                          transform: "scale(0.9)",
                        }}
                      />
                    </StyledLogo>

                    <div style={{ transform: "translateY(-55px)" }}>
                      <Card
                        distributionPercentage={2}
                        distributionType="LEGAL/COMPLIANCE"
                        distributionAmount={(5000000000 * 2) / 100}
                      />
                    </div>
                  </div>
                )}
                <MultiCardWrapper
                  className="flex flex-col g-40 MultiCardWrapper-90 card-width "
                  style={{ width: "33%" }}
                >
                  <Card
                    distributionPercentage={20}
                    distributionType="TEAM/FOUNDERS"
                    distributionAmount={(5000000000 * 20) / 100}
                  />
                  <Card
                    distributionPercentage={15}
                    distributionType="COMMUNITY/DEVELOPERS"
                    // distributionAmount={30000000}
                    distributionAmount={(5000000000 * 15) / 100}
                  />
                  <Card
                    distributionPercentage={2}
                    distributionType="ADVISORS"
                    distributionAmount={(5000000000 * 2) / 100}
                  />
                  <Card
                    distributionPercentage={2}
                    distributionType="BURN"
                    distributionAmount={(5000000000 * 2) / 100}
                  />
                  {/* <Card
                  distributionPercentage={4}
                  distributionType="ECOSYSYTEM GROWTH"
                  distributionAmount={(5000000000 * 4) / 100}
                /> */}
                  {/* <Card
                  distributionPercentage={5}
                  distributionType="TREASURY"
                  distributionAmount={(5000000000 * 5) / 100}
                /> */}
                </MultiCardWrapper>
              </CardWrapper>
            </div>

            <PreScheduleWrapper>
              <ScheduleFullDivider />
              <AboutHeading>Token Lockup Schedule</AboutHeading>
              <AboutSubHeading>
                Imagine a world where your investments grow steadily while
                offering you regular rewards. This approach isn't just about
                returns – it's a strategic path towards a more disciplined
                financial future. 30% of tokens will be unlocked prior to the
                public launch and the remaining 70% will be locked for 12 weeks
              </AboutSubHeading>
              <ScheduleFullDivider />
            </PreScheduleWrapper>

            <div className="slider">
              <PreScheduleWrapper>
                {/* <ScheduleFullDivider /> */}
                <AboutHeading
                  style={{ marginBottom: "30px", color: "#fff" }}
                  className="road-map"
                >
                  Roadmap
                </AboutHeading>

                <div style={{ width: "100%" }}>
                  <Chrono
                    items={items}
                    mode="HORIZONTAL"
                    showAllCardsHorizontal
                    cardWidth={370}
                    cardHeight={350}
                    // enableBreakPoint
                    // verticalBreakPoint={400}
                    // color="linear-gradient(269deg, #fff -10.47%, #7aebf1 86.76%)"
                    lineWidth={7}
                    contentDetailsHeight={100}
                    fontSizes={{
                      title: "1rem",
                    }}
                    slideShow
                    className="my-timeline"
                    classNames={{
                      card: "my-card",
                      cardMedia: "my-card-media",
                      cardSubTitle: "my-card-subtitle",
                      cardText: "my-card-text",
                      cardTitle: "my-card-title",
                      controls: "my-controls",
                      title: "my-title",
                    }}
                  />
                </div>

                {/* <div
              className="w-full flex"
              style={{
                borderRadius: "20px",
                border: "3px solid grey",
                overflow: "hidden",
              }}
            >
              <img src={Roadmap} className="img-cover" />
            </div> */}

                {/* <ScheduleFullDivider /> */}
              </PreScheduleWrapper>
            </div>

            {/* <PreScheduleWrapper>
            <MidSectionTabs />
            <AboutHeading>About Us</AboutHeading>
            <AboutSubHeading>
              At BlockStar Coin, we're a team of visionaries, technologists, and
              miners with a shared goal: to revolutionize the mining industry.
            </AboutSubHeading>
            <ScheduleFullDivider />
          </PreScheduleWrapper>
          <PreScheduleWrapper>
            <AboutHeading>Why Choose BlockStar Coin?</AboutHeading>
            <AboutSubHeading>
              Annual Profit Sharing: At BlockStar Coin, we aim to provide
              investors with a lucrative and expedient mining experience while
              ensuring all coin holders benefit from our annual profitsharing
              plan. It's simple - the longer you hold, the more you earn.
              Holders of 3 months will receive a 5% profit, 6 months will enjoy
              a 10% profit, 9 months will be rewarded with a 15% profit, and a
              remarkable 25% annually for those who hold long-term.
            </AboutSubHeading>
            <AboutSubHeading>
              Presale Season: Get in on the action during our presale season,
              where you can buy BlockStar Coin at a whopping 50% discount off
              the launch price. Don't miss out on this incredible opportunity.
            </AboutSubHeading>
            <AboutSubHeading>
              AI-Powered Mining: Our cutting-edge AI Mining System ensures the
              most efficient and profitable mining experience. We constantly
              analyze market trends to identify the most lucrative mining
              opportunities, so you can maximize your returns.
            </AboutSubHeading>
            <AboutSubHeading>
              With AI-powered mining, exclusive discounts during the presale,
              and the opportunity for annual profit sharing, BlockStar Coin is
              your gateway to a bright future in the world of cryptocurrencies.
              Join us today and become part of the next generation of mining.
            </AboutSubHeading>
            <ScheduleFullDivider />
          </PreScheduleWrapper>

          <PreScheduleWrapper>
            <AboutHeading>Whitepaper</AboutHeading>
            <AboutSubHeading>
              Our whitepaper is your go-to resource for a comprehensive
              understanding of the BlockStar Coin project. It covers every
              aspect, from technical details to our long-term vision. .
            </AboutSubHeading>

            <a href={Whitepaper} download="Whitepaper" target="_blank">
              <LearnButton>Learn More</LearnButton>
            </a>
            <ScheduleFullDivider />
          </PreScheduleWrapper> */}

            <PreScheduleWrapper style={{ gap: "84px" }}>
              {/* <MidSectionTabs /> */}
              <div className="flex flex-col g-24">
                <ScheduleFullDivider />
                <div className="flex flex-col g-8">
                  <AboutHeading>Ready to Get Started?</AboutHeading>
                  <AboutSubHeading>
                    Join us in shaping the future of mining. Invest in BlockStar
                    token today, hodle, and become part of the next generation
                    of cryptocurrency mining
                  </AboutSubHeading>
                </div>
                <ScheduleFullDivider />
              </div>
            </PreScheduleWrapper>
          </div>
        </PreSaleContainer>
      </div>

      <PreScheduleWrapper style={{ gap: "84px" }}>
        <div className="Mining_main">
          <div className="container">
            <PreScheduleWrapper style={{ color: "#fff", textAlign: "center" }}>
              <AboutHeading style={{ color: "#fff" }}>
                BlockStar Coin - AI Mining Mechanism
              </AboutHeading>
              <AboutSubHeading>
                BlockStar Coin AI Mining Mechanisam represents a unique approach
                to the traditional process of cryptocurrency mining. We are
                using Artificial Intelligence (AI) algorithms to detect and
                predict the locations where transactions are most likely to
                occur. This AI-driven approach guides mining activities, aiming
                to optimize the allocation of computational resources and
                increase the efficiency of transaction validation and block
                creation.
              </AboutSubHeading>
              <AboutSubHeading style={{ textAlign: "center" }}>
                Here's how an AI Mining System for BlockStar Coin would work:
              </AboutSubHeading>
            </PreScheduleWrapper>
            <div className="Mining_outer " style={{ marginTop: "10px" }}>
              <div className="Mining_inner">
                <div className="Mining_inner_box">
                  <div className="content_part">
                    <h2>Data Collection</h2>
                    <p>
                      Our system collects and analyses a wide range of data
                      related to historical transaction patterns, network
                      activity, user behaviour, and possibly external factors
                      that influence transaction frequency and distribution.
                    </p>
                  </div>
                  <div className="icon_mining">
                    <img src={Icon2} alt="" />
                  </div>
                </div>
                <div className="Mining_inner_box">
                  <div className="content_part">
                    <h2>Algorithm</h2>
                    <p>
                      {" "}
                      Our AI algorithms- using Machine Learning models and
                      Predictive Analytics, are employed to process and analyze
                      the collected data. These algorithms aim to identify
                      patterns, trends, and correlations that can provide
                      insights into transaction hotspots.
                    </p>
                  </div>
                  <div className="icon_mining">
                    <img src={Icon1} alt="" />
                  </div>
                </div>
                <div className="Mining_inner_box">
                  <div className="content_part">
                    <h2>Transactional Prediction</h2>
                    <p>
                      Based on the analysis, our AI system predicts areas where
                      transaction activity is likely to be concentrated. These
                      predictions can be made in real-time or periodically,
                      taking into account changing usage patterns.
                    </p>
                  </div>
                  <div className="icon_mining">
                    <img src={Icon5} alt="" />
                  </div>
                </div>
              </div>
              <div className="Mining_img">
                <img src={MainLogo} alt="" />
              </div>
              <div className="Mining_inner Mining_right">
                <div className="Mining_inner_box">
                  <div className="icon_mining">
                    <img src={Icon3} alt="" />
                  </div>
                  <div className="content_part">
                    <h2>Mining Resource Allocation</h2>
                    <p>
                      Miners adjust their computational resources and mining
                      efforts based on the predictions generated by the AI
                      system. Focusing mining activities on the regions or nodes
                      where transactions are expected to occur more frequently.
                      This adaptive approach aims to increase the chances of
                      successfully validating transactions and creating new
                      blocks.
                    </p>
                  </div>
                </div>
                <div className="Mining_inner_box">
                  <div className="icon_mining">
                    <img src={Icon4} alt="" />
                  </div>
                  <div className="content_part">
                    <h2>Efficiency Improvemen</h2>
                    <p>
                      By concentrating mining efforts in predicted transaction
                      hotspots, Our AI Mining System aims to enhance the overall
                      efficiency of the mining process. This will lead to
                      quicker transaction confirmations, reduced energy
                      consumption, and a potentially higher rate of earning
                      rewards for our miners.
                    </p>
                  </div>
                </div>
                <div className="Mining_inner_box">
                  <div className="icon_mining">
                    <img src={Icon6} alt="" />
                  </div>
                  <div className="content_part">
                    <h2>Feedback Loop</h2>
                    <p>
                      Our AI system continuously learns and improves its
                      predictions over time. It incorporates new transaction
                      data and adjusts its algorithms based on the accuracy of
                      its previous predictions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PreScheduleWrapper>
    </>
  );
};

export default About;
