import React from "react";
import "./App.css";
import Hero from "./components/Sections/Hero";
import { Web3ReactProvider } from "@web3-react/core";
import {
  ExternalProvider,
  JsonRpcFetchFunc,
  Web3Provider,
} from "@ethersproject/providers";
import Web3ReactManager from "./components/Web3ReactManager";
import About from "./components/Sections/About";
import InviteFriends from "./components/Sections/Invite";
// import Contact from "./components/Sections/Contact";
import Footer from "./components/Sections/Footer";
import { Provider } from "react-redux";
import store from "./store";
import { QueryResolver } from "./components/QueryResolver";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const getLibrary = (provider: ExternalProvider | JsonRpcFetchFunc) => {
    return new Web3Provider(provider);
  };
  return (
    <div className="App">
      <Provider store={store}>
        <Web3ReactProvider getLibrary={getLibrary}>
          <Hero />
          <ToastContainer />

          <About />
          <InviteFriends />
          {/* <Contact /> */}
          <Footer />
          <Web3ReactManager />
          <QueryResolver />
        </Web3ReactProvider>
      </Provider>
    </div>
  );
}

export default App;
