import * as React from "react";
import { styled } from "@mui/material/styles";
// import Box from "@mui/material/Box";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 7,
  borderRadius: 20,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "rgba(28, 39, 76, 0.80)",
    //   theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 7,
    background: "linear-gradient(269deg, #7AEBF1 -10.47%, #1C274C 86.76%)",
    // theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

interface ProgressProps {
  percentage: number;
}

const ThinProgress = ({ percentage }: ProgressProps) => {
  return (
    <>
      <BorderLinearProgress variant="determinate" value={percentage} />
    </>
  );
};

export default ThinProgress;
