/**@ts-ignore */
import * as React from "react";

import useMediaQuery from "@mui/material/useMediaQuery";
export const useMobile = () => {
  const mobile = useMediaQuery("(max-width:767px)");
  return mobile;
};
export const useMobile2 = () => {
  const mobile = useMediaQuery("(max-width:600px)");
  return mobile;
};
export const useMobile3 = () => {
  const mobile = useMediaQuery("(max-width:430px)");
  return mobile;
};
export const useIpad = () => {
  const mobile = useMediaQuery("(min-width:768px) and (max-width:900px)");
  return mobile;
};
export const useMiniDesk = () => {
  const mobile = useMediaQuery("(min-width:901px) and (max-width:1330px)");
  return mobile;
};
export const useMiniDesk2 = () => {
  const mobile = useMediaQuery("(max-width:1330px) and (max-width:1443px)");
  return mobile;
};
