import styled from "styled-components";
import Button from "@mui/material/Button";
// import PresaleButton from "../../Shared/Button";
import { MediaBreakpoints } from "../../../mediaQuery";

export const InviteWrapper = styled.div`
  width: 100%;
  border-radius: 10px;
  padding: 16px 24px;
  // background: linear-gradient(91deg, #7aebf1 18.61%, #fff 103.19%);
  background: #2d323a;
  box-shadow: 0px 4px 20px 0px rgba(122, 235, 241, 0.2);
`;

export const InsideWrapper = styled.div`
  width: 100%;
  padding: 16px 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (${MediaBreakpoints.mobile}) {
    flex-direction: column;
  }

  ${MediaBreakpoints.ipad} {
    flex-direction: column;
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  //   border: 1px solid #7aebf1;

  display: flex;
  align-items: center;
  //   padding: 10px 0;

  overflow: hidden;

  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
    flex-direction: column;
    gap: 16px;
    overflow: initial;
  }

  ${MediaBreakpoints.ipad} {
    width: 100%;
    flex-direction: column;
    gap: 16px;
    overflow: initial;
  }
`;
export const InputWrapperName = styled.div`
  width: 70%;
  //   border: 1px solid #7aebf1;

  display: flex;
  align-items: center;
  //   padding: 10px 0;

  overflow: hidden;

  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
    flex-direction: column;
    gap: 16px;
    overflow: initial;
  }

  ${MediaBreakpoints.ipad} {
    width: 100%;
    flex-direction: column;
    gap: 16px;
    overflow: initial;
  }
`;

export const InviteInputField = styled.input`
  width: 85%;
  padding: 16px;
  //   border: 3px solid blue;

  border: none;
  color: #fff;
  font-size: 16px;
  opacity: 0.5;
  outline: none;
  // background: #1c274c;
  background: #2d323a;
  border: 1px solid #fff;

  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
    border-radius: 100px;
  }

  ${MediaBreakpoints.ipad} {
    width: 100%;
    border-radius: 100px;
  }
`;

export const BtnWrapper = styled.div`
  width: 15%;

  //   border-radius: 100px !important;
  // background: #1c274c;
  background: #74fcfc;
  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
    border-radius: 100px;
  }

  ${MediaBreakpoints.ipad} {
    width: 100%;
    border-radius: 100px;
  }
`;

export const SendButton = styled(Button)`
  border-radius: 100px !important;
  //   border: 1px solid #7aebf1 !important;
  //   opacity: 0.8 !important;
  // background: #1c274c !important;
  background: #74fcfc !important;
  // color: #7aebf1 !important;
  color: #000 !important;
  padding: 12px !important;
  text-transform: capitalize !important;
  font-family: Inter !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  width: 100% !important;
`;

export const DisSendButton = styled(Button)`
  border-radius: 100px !important;
  //   border: 1px solid #7aebf1 !important;
  //   opacity: 0.8 !important;
  // background: #1c274c !important;
  background: rgba(116, 252, 252, 0.5) !important;
  pointer-events: none !important;

  color: #000 !important;
  padding: 12px !important;
  text-transform: capitalize !important;
  font-family: Inter !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  width: 100% !important;
`;

export const Heading = styled.h1`
  // color: #1c274c;
  color: #fff;

  text-align: center;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (${MediaBreakpoints.mobile}) {
    font-size: 24px;
  }
`;
