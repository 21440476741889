import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import user from "./user";
import presale from "./presale";

const store = configureStore({
  reducer: {
    auth: authReducer,
    user,
    presale,
  },
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
