import styled from "styled-components";
// import PresaleButton from "../../Shared/Button";
import Button from "@mui/material/Button";
import { MediaBreakpoints } from "../../../mediaQuery";
import Circle from "../../../assets/circle.png";

export const Heading = styled.h1`
  color: #fff;
  font-family: Inter;
  font-size: 62px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px;
  letter-spacing: -2px;

  @media (${MediaBreakpoints.mobile}) {
    font-size: 32px;
    text-align: center;
    line-height: 47px;
  }

  ${MediaBreakpoints.ipad} {
    font-size: 40px;
    text-align: center;
  }
`;

export const Subheading = styled.p`
  color: #b9c0d0;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.5px;
  text-align: justify;
  @media (${MediaBreakpoints.mobile}) {
    font-size: 15px;
    line-height: 26px;
    text-align: justify;
  }

  ${MediaBreakpoints.ipad} {
    font-size: 16px;
    text-align: center;
  }
`;

export const HeroWrapper = styled.div`
  padding: 45px 0 150px;
  gap: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (${MediaBreakpoints.mobile}) {
    flex-direction: column;
    padding: 0 0 60px;
  }

  ${MediaBreakpoints.ipad} {
    flex-direction: column;
  }
`;

export const LeftContainer = styled.div`
  width: 50%;
  gap: 24px;
  margin-top: 70px;
  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
    gap: 16px;
    align-items: center;
  }

  ${MediaBreakpoints.ipad} {
    width: 100%;
    gap: 16px;
    align-items: center;
  }
`;

export const RightContainer = styled.div`
  width: 50%;

  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
  }

  ${MediaBreakpoints.ipad} {
    width: 100%;
  }
`;

export const SwapWrapperMain = styled.div`
  width: 540px;
  gap: 24px;
  display: flex;
  flex-direction: column;

  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
  }

  ${MediaBreakpoints.ipad} {
    width: 100%;
  }
`;

export const SwapWrapper = styled.div`
  padding: 40px 45px;
  gap: 24px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  border: 0.5px solid #fff;
  background: #21252e;
  box-shadow: 0px 4px 50px 0px rgba(115, 251, 253, 0.41);
  position: relative;
  z-index: 1;

  &::after {
    content: "";
    position: absolute;
    top: -40px;
    right: -50px;
    height: 200px;
    width: 200px;
    background-image: url(${Circle});
    background-size: 200px;
    background-position: center;
    z-index: -1;
  }
  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
    border-radius: 8px;
    padding: 22px;
  }

  ${MediaBreakpoints.ipad} {
    width: 100%;
  }
`;

export const HeadingWrapper = styled.div`
  @media (${MediaBreakpoints.mobile}) {
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: center;
  }

  ${MediaBreakpoints.ipad} {
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: center;
  }
`;

export const BuyText = styled.p`
  color: #fff;

  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media (${MediaBreakpoints.mobile}) {
    font-size: 16px;
  }
`;

export const AmountInoutWrapper = styled.div`
  @media (${MediaBreakpoints.mobile}) {
    flex-direction: column;
  }
`;

export const LiveButton = styled(Button)`
  width: 90px !important;
  border-radius: 50px !important;
  background: #73fbfd !important;
  color: #000 !important;
  font-family: Inter !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  height: 52px !important;
  text-transform: uppercase !important;
  border: 12px solid #474f67b0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
`;

export const Digit = styled.div`
  color: #d9d9d9;

  text-align: right;
  leading-trim: both;

  text-edge: cap;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (${MediaBreakpoints.mobile}) {
    font-size: 12px;
  }
`;
export const LgDigit = styled(Digit)`
  font-weight: 700;
  font-size: 26px;
  @media (${MediaBreakpoints.mobile}) {
    font-size: 16px;
  }
`;

export const MdDigit = styled(Digit)`
  font-weight: 700;
`;

export const Divider = styled.div`
  border-radius: 10px;
  background: #7aebf1;
  height: 1px;
  flex: 1 1;
  // width: 20%;
`;

export const SoldPriceWrapper = styled.div`
  width: 100%;

  padding-left: 1.25rem;
  padding-right: 1.25rem;
`;

export const TokenWrapper = styled.div`
  width: 20px;
  height: 20px;
  overflow: hidden;
  margin-right: 10px;
`;

export const AmountWrapper = styled.div`
  width: 50%;
  height: 41.291px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8.096px;
  border: 0.81px solid #474f67;
  background: #474f67;
  text-align: center;
  padding: 0 24px;
  @media (${MediaBreakpoints.mobile}) {
    width: 100%;
  }

  ${MediaBreakpoints.ipad} {
    width: 100%;
  }
`;

export const InputField = styled.input`
  width: 100%;
  outline: none;
  border: none;
  // padding: 8px;
  font-size: 15px;
  background: transparent;
  color: #fff;
}
`;
